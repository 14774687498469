import React from 'react';

export const MantraUnstaking = () => {
  return (
    <div className="has-text-centered mb-6 mr-6 ml-6">
      <div className="notification pt-5">
        <p className="title is-2 mb-5">You are unstaking</p>
        <p className="subtitle is-2">50 Cirus</p>
      </div>
      <p className="mt-6 has-text-left">
        <span className="has-text-primary">*</span> There is an 8 days Unstaking
        period before you can Withdraw your Staked tokens. Following that 8 day
        Unstaking period you will be able to Withdraw .
      </p>
      <p className="mt-3 has-text-left">
        <span className="has-text-primary">*</span> Staking Rewards will stop
        being earned as soon as you click “Unstake” and initiate the Unstaking
        process.
      </p>

      <p className="mb-5 mt-6">
        <button className="button is-primary is-rounded is-large">
          Unstake
        </button>
      </p>
    </div>
  );
};
