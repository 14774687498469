export const initialBalances = () => ({
  total: '0',
  liquidEthereum: '0',
  liquidPolygon: '0',
  liquid: '0',
  totalStaked: '0',
  stakedContract: '0',
  stakedPool: '0',
  rewards: '0',
  stakedContractUSD: '0',
  stakedPoolUSD: '0',
  totalUSD: '0.00',
  liquidUSD: '0.00',
  liquidUSDEthereum: '0.00',
  liquidUSDPolygon: '0.00',
  totalStakedUSD: '0.00',
  rewardsUSD: '0.00',
  apy: '0',
  stakingContractTotalBalance: '0.00',
  stakingContractTotalBalanceUSD: '0.00',
});
