import { ACTION_TYPES } from '../actionTypes';
import axios from 'axios';

export function fetchCirusValueUSD() {
  return function (dispatch) {
    return axios
      .get(
        'https://api.coingecko.com/api/v3/simple/price?ids=cirus&vs_currencies=USD',
      )
      .then(async ({ data }) => {
        dispatch(await setCirusUSD(data.cirus.usd));
      });
  };
}

export const setCirusUSD = async (data) => ({
  type: ACTION_TYPES.CURRENCY_SET_CIRUS_USD,
  payload: data,
});
