import React from 'react';
import { cnb } from 'cnbuilder';
import { PropTypes } from 'prop-types';
import { ConvertMoveFundsImportant } from './convertMoveFundsImportant';
import { ConvertMoveFundsOverview } from './convertMoveFundsOverview';
import { ConvertMoveFundsConfirm } from './convertMoveFundsConfirm';
import { ConvertMoveFundsProgress } from './convertMoveFundsProgress';

export const ConvertMoveFundsModal = ({
  isActive,
  onClose,
  amount,
  onTransfer,
  modal,
  modalTitle,
  depositTx,
  onsetModalOverview2,
  onTransfer2,
}) => {
  return (
    <div className={cnb('transfer', 'modal', { 'is-active': isActive })}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <div className="box pt-6 pr-6 pb-6 pl-6">
          <div className="p-5">
            <button
              className="modal-close-button is-pulled-right"
              aria-label="close"
              title="Close"
              onClick={onClose}
            >
              <i className="fa fa-times"></i>
            </button>
            {/*<ConvertMoveFundsImportant />
            <ConvertMoveFundsConfirm />*/}
            {modal == 'overview1' ? (
              <ConvertMoveFundsImportant
                onsetModalOverview2={onsetModalOverview2}
              />
            ) : modal == 'overview2' ? (
              <ConvertMoveFundsOverview
                amount={amount}
                onTransfer={onTransfer}
              />
            ) : modal == 'review' ? (
              <ConvertMoveFundsConfirm
                amount={amount}
                onTransfer2={onTransfer2}
              />
            ) : modal == 'progress' ? (
              <ConvertMoveFundsProgress amount={amount} depositTx={depositTx} />
            ) : modal == 'loading' ? (
              <div className="column">
                <div className="card-content loading-card has-text-centered">
                  <p className="title is-4">{modalTitle}</p>
                  <i className="fas fa-circle-notch fa-5x fa-spin has-text-primary" />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
ConvertMoveFundsModal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.any, //fix anytype
  amount: PropTypes.any,
  onTransfer: PropTypes.any,
  onTransfer2: PropTypes.any,
  modal: PropTypes.any,
  modalTitle: PropTypes.any,
  depositTx: PropTypes.any,
  onsetModalOverview2: PropTypes.any,
};
