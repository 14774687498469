import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { throttle } from 'lodash';
import AmountBox from './../amountBox';
import './scrolledMobileTable.scss';

const ScrolledMobileTable = ({ balances, isExpanded }) => {
  const {     total,
    totalUSD,
    liquid,
    liquidUSD,
    totalStaked,
    totalStakedUSD, } = balances;

  const [isVisible, setIsVisible] = useState(false);
  const [scrolledInfoTableHeight, setScrolledInfoTableHeight] = useState(81);
  const scrolledInfoTable = useRef(null);

  const topTableElement = document.getElementsByClassName('topTableElement');

  const onScroll = throttle(() => {
    if (!topTableElement[0]) {
      setIsVisible(false);
      return;
    }
    const height = topTableElement[0].getBoundingClientRect().height;
    const top = topTableElement[0].getBoundingClientRect().top;
    setIsVisible(height + top - scrolledInfoTableHeight < 0);
  }, 200);

  useLayoutEffect(() => {
    if (scrolledInfoTable.current.clientHeight > 0) {
      setScrolledInfoTableHeight(scrolledInfoTable.current.clientHeight);
    }
  }, [isVisible]);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  }, [isExpanded]);

  return (
    <div className={`${isVisible ? '' : 'is-hidden'}`}>
      <div ref={scrolledInfoTable}>
        <nav className="top-info-bar navbar is-fixed-top is-primary columns is-mobile m-0">
          <div className="column">
          <AmountBox
            topTxt="Total Balance"
            middleTxt={`${new Intl.NumberFormat().format(total)} CIRUS`}
            bottomTxt={`$${new Intl.NumberFormat().format(totalUSD)} USD`}
             />
          </div>
          <div className="column">
            <AmountBox
              topTxt="Liquid Balance"
              middleTxt={`${new Intl.NumberFormat().format(liquid)} CIRUS`}
              bottomTxt={`$${new Intl.NumberFormat().format(liquidUSD)} USD`}
            />
          </div>
          <div className="column">
            <AmountBox
              topTxt="Staked Balance"
              middleTxt={`${new Intl.NumberFormat().format(totalStaked)} CIRUS`}
              bottomTxt={`$${new Intl.NumberFormat().format(totalStakedUSD)} USD`}
            />
          </div>
        </nav>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    balances: state.balances,
    isExpanded: state.mobileTable.isExpanded,
  };
};
export default connect(mapStateToProps)(ScrolledMobileTable);
