export const getNetworkName = (chainId) => {
  switch (chainId) {
    case 1:
      return 'Ethereum Mainnet';
      break;
    case 5:
      return 'Goerli';
      break;
    case 80001:
      return 'Mumbai';
      break;
    case 137:
      return 'Polygon Mainnet';
      break;
    default:
      return 'Unknown';
      break;
  }
};

export const timeConverter = (UNIX_timestamp) => {
  var a = new Date(UNIX_timestamp * 1000);
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = month + ' ' + date + ', ' + year;
  return time;
};

export const toFloat = (str, radix) => {
  var parts = str.split('.');
  if (parts.length > 1) {
    return (
      parseInt(parts[0], radix) +
      parseInt(parts[1], radix) / Math.pow(radix, parts[1].length)
    );
  }
  return parseInt(parts[0], radix);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
