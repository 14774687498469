/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import './makeMobileTableTile.scss';

export const MakeMobileTableTile = (
  secondRowLeftTitle,
  secondRowRightTitle,
  thirdRowLeftTitle,
  thirdRowRightTitle,
  isButton,
  buttonContent,
) => {
  return ({
    tokenName,
    tokenIcon,
    firstRowRight,
    secondRowLeftContent,
    secondRowRightContent,
    thirdRowLeftContent,
    thirdRowRightContent,
    buttonFunction,
  }) => {
    return (
      <>
        <div className="make-mobile-table column">
          {/* <div className="columns is-mobile level"> */}
          <div className="columns is-mobile level">
            <div className="column is-narrow">
              <div className="token-background">
                <img alt={`${tokenName} icon`} src={tokenIcon} height="18" />
              </div>
            </div>
            <div className="column is-narrow">{tokenName}</div>
            {/* </div> */}
            <div className="column m-0">
              <p className="is-primary">{firstRowRight}</p>
            </div>
          </div>
          <div className="columns is-mobile">
            <div className="column">
              <p className="dull mobile-subtitle">{secondRowLeftTitle}</p>
              {secondRowLeftContent}
              {/* {tokenQuantity} {tokenNameShort} */}
            </div>
            <div className="column">
              <p className="dull mobile-subtitle">{secondRowRightTitle}</p>
              {/* {`$${dollarValue}`} */}
              {secondRowRightContent}
            </div>
          </div>
          <div className="columns is-mobile">
            <div className="column">
              <p className="dull mobile-subtitle">{thirdRowLeftTitle}</p>
              {thirdRowLeftContent}
            </div>
            <div className="column">
              <p className="dull mobile-subtitle">{thirdRowRightTitle}</p>
              {thirdRowRightContent}
            </div>
          </div>
          <div
            className={`columns has-text-centered ${
              isButton ? '' : 'is-hidden'
            }`}
          >
            <div className="column">
              <button
                className="button is-primary is-rounded is-large"
                onClick={() => buttonFunction()}
              >
                {buttonContent}
              </button>
            </div>
          </div>
        </div>
        <hr />
      </>
    );
  };
};

// MakeMobileTableTile.propTypes = {
//   tokenName: PropTypes.string,
//   tokenNameShort: PropTypes.string,
//   tokenIcon: PropTypes.string,
//   firstColumnRight: PropTypes.string,
//   firstRowRight: PropTypes.string,
//   secondRowLeftTitle: PropTypes.string,
//   secondRowLeftContent: PropTypes.string,
//   secondRowRightTitle: PropTypes.string,
//   secondRowRightContent: PropTypes.string,
//   thirdRowLeftTitle: PropTypes.string,
//   thirdRowLeftContent: PropTypes.string,
//   thirdRowRightTitle: PropTypes.string,
//   thirdRowRightContent: PropTypes.string,
//   isButton: PropTypes.bool,
//   buttonFunction: PropTypes.func,
//   buttonContent: PropTypes.string,
// };
