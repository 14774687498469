const infuraId = '8c894b55e67d4d4ca40c405db1e360dd';

module.exports = {
  root: {
    5: {
      RPC: 'https://goerli.infura.io/v3/' + infuraId,
      POSRootChainManager: '0xBbD7cBFA79faee899Eaf900F13C9065bF03B1A74',
      DERC20: '0x655F2166b0709cd575202630952D71E2bB0d61Af',
      posERC20Predicate: '0xdD6596F2029e6233DEFfaCa316e6A95217d4Dc34',
      posEtherPredicate: '0xe2B01f3978c03D6DdA5aE36b2f3Ac0d66C54a6D5',
    },
    1: {
      RPC: 'https://mainnet.infura.io/v3/' + infuraId,
      POSRootChainManager: '0xA0c68C638235ee32657e8f720a23ceC1bFc77C77',
      posERC20Predicate: '0x40ec5B33f54e0E8A33A975908C5BA1c14e5BbbDf',
      posEtherPredicate: '0x8484Ef722627bf18ca5Ae6BcF031c23E6e922B30',
    },
  },
  child: {
    80001: {
      RPC: 'https://polygon-amoy.infura.io/v3/' + infuraId,
      DERC20: '0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1',
    },
    137: {
      RPC: 'https://polygon-mainnet.infura.io/v3/' + infuraId,
    },
  },
  tokenToAddressMap: {
    /*cirus: {
      ethereum: '0x0d7869a842c9115806a1e766163335be5B64b4ea',
      matic: '0x0d7869a842c9115806a1e766163335be5B64b4ea',
    },*/
    cirus: {
      ethereum: '0x58E2B544E389100A8851b8F80440D9436B68EB25',
      matic: '0x28A5c17A312f40DFA2AB9bab0E36Bc91aade53A4',
    },
    /*cirus_testnet: {
      ethereum: '0x0d7869a842c9115806a1e766163335be5B64b4ea',
      matic: '0x0d7869a842c9115806a1e766163335be5B64b4ea',
    },*/
  },
  erc20Tokens: {
    mainnet: ['cirus'],
    testnet: ['cirus'],
  },
  infuraId,
};
