/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setMobileTableExpanded } from './../../store/actions/mobileTableActions';
import './mobileTopTable.scss';
import AmountBox from '../amountBox';
import totalIcon from '../../images/Cirus Dash Icons/1TotalBalance@4x.png';
import liquidIcon from '../../images/Cirus Dash Icons/2LiquidBalance@4x.png';
import stakedIcon from '../../images/Cirus Dash Icons/3StakedBalance@4x.png';
import rewardIcon from '../../images/Cirus Dash Icons/4RewardBalance@4x.png';
// import multiplierIcon from '../../images/Cirus Dash Icons/5MultiplierAmount@4x.png';
// import engagementIcon from '../../images/Cirus Dash Icons/6EngagementIndex@4x.png';
// import dataRevenueIcon from '../../images/Cirus Dash Icons/7DataRevenue@4x.png';
// import currentTierIcon from '../../images/Cirus Dash Icons/8CurrentTier@4x.png';

const MobileTopTable = ({ balances, mobileTable, setMobileTableExpanded }) => {
  const {
    total,
    totalUSD,
    liquid,
    liquidUSD,
    rewards,
    rewardsUSD,
    totalStaked,
    totalStakedUSD,
  } = balances;
  const { isExpanded } = mobileTable;
  const setIsExpanded = (isExpanded) => {
    setMobileTableExpanded(isExpanded);
  };
  // const [isExpanded, setIsExpanded] = useState(true);
  // height of the first row that is always visible
  const [firstRowHeight, setFirstRowHeight] = useState();
  // current height of the expandable row(s)
  const [secondRowHeight, setSecondRowHeight] = useState();
  // height of second row depending on screen size
  // imports breakpoint hook that returns a string that describes the screen size
  const screenSize = useBreakpoint();
  useEffect(() => {
    if (
      (screenSize === 'sm' || screenSize === 'xs' || screenSize === 'xxs') &&
      isExpanded !== false
    ) {
      setIsExpanded(false);
    } else if (
      !(screenSize === 'sm' || screenSize === 'xs' || screenSize === 'xxs') &&
      isExpanded !== true
    ) {
      setIsExpanded(true);
    }
  }, [screenSize]);

  // const getFirstRowHeight = useRef(null);
  // const getSecondRowHeight = useRef(null);
  // useLayoutEffect(() => {
  //   setFirstRowHeight(getFirstRowHeight.current.clientHeight);
  //   setSecondRowHeight(getSecondRowHeight.current.clientHeight);
  // }, [screenSize]);
  return (
    <div className="columns m-0">
      <div className="top-table column pt-0 p-0">
        <div className="top-table-first-row columns m-0 p-0">
          <div className="column m-0 p-0">
            <div className="mobile-table-row columns is-mobile m-0">
              <div className="column mobile-table-item">
                <AmountBox
                  topTxt="Total Balance"
                  middleTxt={`${new Intl.NumberFormat().format(total)} CIRUS`}
                  bottomTxt={`$${new Intl.NumberFormat().format(totalUSD)} USD`}
                  avatar={totalIcon}
                  className="top-table-box"
                />
              </div>
              <div className="column mobile-table-item">
                <AmountBox
                  topTxt="Liquid Balance"
                  middleTxt={`${new Intl.NumberFormat().format(liquid)} CIRUS`}
                  bottomTxt={`${new Intl.NumberFormat().format(liquidUSD)} USD`}
                  avatar={liquidIcon}
                  className="top-table-box"
                />
              </div>
            </div>
          </div>
          <div className="column  m-0 p-0">
            <div className="mobile-table-row columns is-mobile m-0">
              <div className="column mobile-table-item">
                <AmountBox
                  topTxt="Staked Balance"
                  middleTxt={`${new Intl.NumberFormat().format(
                    totalStaked,
                  )} CIRUS`}
                  bottomTxt={`${new Intl.NumberFormat().format(
                    totalStakedUSD,
                  )} USD`}
                  avatar={stakedIcon}
                  className="top-table-box"
                />
              </div>
              <div className="column mobile-table-item">
                <AmountBox
                  topTxt="Reward Balance"
                  middleTxt={`${new Intl.NumberFormat().format(rewards)} CIRUS`}
                  bottomTxt={`${new Intl.NumberFormat().format(
                    rewardsUSD,
                  )} USD`}
                  avatar={rewardIcon}
                  className="top-table-box"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="expandable-table"
          ref={getSecondRowHeight}
          style={{ height: isExpanded ? firstRowHeight : '0' }}
        >
          <div className="columns m-0 p-0" ref={getFirstRowHeight}>
            <div className="column m-0 p-0">
              <div className="mobile-table-row columns is-mobile m-0">
                <div className="column mobile-table-item">
                  <AmountBox
                    topTxt="Multiplier Amount"
                    middleTxt="0.5x"
                    avatar={multiplierIcon}
                    className="top-table-box"
                  />
                </div>
                <div className="column mobile-table-item">
                  <AmountBox
                    topTxt="Engagement Index"
                    middleTxt="500"
                    avatar={engagementIcon}
                    className="top-table-box"
                  />
                </div>
              </div>
            </div>
            <div className="column m-0 p-0">
              <div className="mobile-table-row columns is-mobile m-0">
                <div className="column mobile-table-item">
                  <AmountBox
                    topTxt="Total Data Revenue"
                    middleTxt="Coming soon"
                    avatar={dataRevenueIcon}
                    className="top-table-box"
                  />
                </div>
                <div className="column mobile-table-item">
                  <AmountBox
                    topTxt="Current Tier"
                    middleTxt="Tier 3"
                    avatar={currentTierIcon}
                    className="top-table-box"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* href linter disabled at top */}
        {/* <a
          className="is-primary is-hidden-desktop is-hidden-tablet"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="columns column is-centered is-mobile p-0 m-0">
            <p className="expand-metrics-text btn-expand-metrics">
              {isExpanded && <>Close expanded metrics</>}
              {!isExpanded && <>See all metrics</>}
            </p>
          </div>
        </a> */}
      </div>
    </div>
  );
};

MobileTopTable.propTypes = {
  total: PropTypes.number,
  totalUSD: PropTypes.number,
  liquid: PropTypes.number,
  liquidUSD: PropTypes.number,
  rewards: PropTypes.number,
  rewardsUSD: PropTypes.number,
  totalStaked: PropTypes.number,
  totalStakedUSD: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    balances: state.balances,
    mobileTable: state.mobileTable,
  };
};
export default connect(mapStateToProps, (dispatch) => ({
  dispatch,
  ...bindActionCreators({ setMobileTableExpanded }, dispatch),
}))(MobileTopTable);
