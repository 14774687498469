import React from 'react';
import AmountBox from '../../../../components/amountBox';
import { PropTypes } from 'prop-types';
import cirusToken from '../../../../images/cirusToken.png';

export const MantraStakedForm = ({
  toggleIsMantraUnstakeModalActive,
  toggleIsMantraStakingModalActive,
  availableForWithdrawal,
  withdraw,
  unstakedTokens,
}) => {
  return (
    <>
      <div className="columns is-centered">
        <div className="column is-4 ml-6">
          <AmountBox middleTxt="Cirus" avatar={cirusToken} />
        </div>
      </div>
      <hr className="mt-2" />
      <div className="columns mt-6 mb-6">
        <div className="column has-text-centered">
          <button
            className="button is-primary is-rounded is-large"
            onClick={() => toggleIsMantraStakingModalActive('stakeAmount')}
          >
            Stake more
          </button>
          <br />
          <br />
          <button
            className="button is-primary is-outlined is-rounded is-large"
            onClick={() => toggleIsMantraUnstakeModalActive()}
          >
            Unstake
          </button>
        </div>
      </div>
      <hr />
      <div className="has-text-centered mt-6 mb-6">
        <p className="mb-3">Unstaked</p>
        <p className="subtitle has-text-weight-bold mb-5">
          {unstakedTokens} Cirus
        </p>
        <p className="mb-3">Available for withdrawal</p>
        <p className="subtitle has-text-weight-bold mb-5">
          {availableForWithdrawal} Cirus
        </p>
        <button
          className="button is-primary is-outlined is-rounded is-large"
          onClick={(e) => withdraw()}
        >
          Withdraw
        </button>
      </div>
    </>
  );
};
MantraStakedForm.propTypes = {
  toggleIsMantraUnstakeModalActive: PropTypes.func,
  toggleIsMantraStakingModalActive: PropTypes.func,
  withdraw: PropTypes.func,
  availableForWithdrawal: PropTypes.number,
  unstakedTokens: PropTypes.number,
};
