import { ACTION_TYPES } from '../actionTypes';
import { combineReducers } from 'redux';
import { initialNotifications } from './../initialState/notifications';

const notifications = (state = initialNotifications(), action) => {
  if (action.type === ACTION_TYPES.NOTIFICATIONS_SET) {
    return action.payload;
  }
  return state;
};

export default combineReducers({
  notifications,
});
