import React from 'react';
import { MakeMobileTableTile } from './../../../../components/tables/makeMobileTableTile';
import cirusIcon from '../../../../images/cirusToken.png';
import ethereumIcon from '../../../../images/ethicon.png';
import maticIcon from '../../../../images/maticToken.png';

export const ConvertMobileTransaction = ({ transactions }) => {
  const ConvertMobileTransactionTile = MakeMobileTableTile(
    'Amount',
    'Value',
    'Status',
    'Date',
    false,
    '',
  );
  return (
    <div className="columns m-0 pt-6 pb-6 clear-last-break is-hidden-tablet">
      {transactions.map((t, key) => {
        return (
          <ConvertMobileTransactionTile
            key={key}
            tokenName={'Cirus ERC-20'}
            tokenIcon={cirusIcon}
            secondRowLeftContent={t.amount}
            secondRowRightContent={'20,000.00'}
            firstRowRight={t.action}
            thirdRowLeftContent={'Pending'}
            thirdRowRightContent={t.date}
          />
        );
      })}
    </div>
  );
};
