import React from 'react';
import PropTypes from 'prop-types';

const AmountBox = (props) => {
  const {
    topTxt,
    middleTxt,
    bottomTxt,
    avatar,
    className,
    isIconMobile = false,
  } = props;

  return (
    <div
      className={`columns is-vcentered media amount-box ${className} level m-0`}
    >
      <div
        className={`${
          isIconMobile ? '' : 'is-hidden-mobile'
        }  column is-narrow is-borderless media-left ${
          avatar ? '' : 'is-hidden'
        }`}
      >
        <figure className="image m-0 columns is-vcentered is-mobile">
          <img
            src={avatar}
            className="is-rounded p-0 column is-narrow p-0"
            alt={topTxt}
          />
        </figure>
      </div>
      <div className="p-0 column is-borderless overflow-x-inherit">
        <div className="media-content">
          <p
            className={`top-txt ${middleTxt ? '' : 'mb-2'} ${
              topTxt ? '' : 'is-hidden'
            }`}
          >
            {topTxt}
          </p>
          <p className={`middle-txt ${middleTxt ? '' : 'is-hidden'}`}>
            {middleTxt}
          </p>
          <p className={`bottom-txt ${bottomTxt ? '' : 'is-hidden'}`}>
            <small>{bottomTxt}</small>
          </p>
        </div>
      </div>
    </div>
  );
};

AmountBox.propTypes = {
  topTxt: PropTypes.string,
  middleTxt: PropTypes.string,
  bottomTxt: PropTypes.string,
  avatar: PropTypes.string,
  className: PropTypes.string,
  isIconMobile: PropTypes.bool,
};

export default AmountBox;
