import { combineReducers } from 'redux';
import homeView from '../../views/designElements/designElementsViewReducer';
import balancesReducer from './balancesReducer.js';
import mobileTableReducer from './mobileTableReducer';
import notificationsReducer from './notificationsReducer';
import currencyReducer from './currencyReducer';

const rootReducer = combineReducers({
  homeView,
  balances: balancesReducer,
  notifications: notificationsReducer,
  mobileTable: mobileTableReducer,
  currencyConversion: currencyReducer,
});

export default rootReducer;
