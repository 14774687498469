import React from 'react';
import { PropTypes } from 'prop-types';
import './convertTokenTile.scss';

export const TokenTile = ({
  tokenName,
  tokenNameShort,
  tokenQuantity,
  tokenIcon,
  dollarValue,
}) => {
  return (
    <tr>
      <th />
      <th className="convert-table-icon vcenter">
        <div className="token-background">
          <img
            alt={`${tokenName} icon`}
            src={tokenIcon}
            width="18"
            height="18"
          />
        </div>
      </th>
      <td className="vcenter">
        <p>{tokenName}</p>
      </td>
      <td className="vcenter pr-6">
        <p className="pr-6">
          {`${new Intl.NumberFormat().format(
            parseFloat(tokenQuantity).toFixed(2),
          )} ${tokenNameShort}`}
        </p>
      </td>
      <td className="vcenter">
        {`$${new Intl.NumberFormat().format(
          parseFloat(dollarValue).toFixed(2),
        )}`}
      </td>
      {/* <td className="vcenter">
        <a className="is-primary pr-6">Deposit</a>
      </td>
      <td className="vcenter">
        <a className="is-primary pr-6">Withdraw</a>
      </td> */}
      <td />
    </tr>
  );
};

TokenTile.propTypes = {
  tokenName: PropTypes.string,
  tokenNameShort: PropTypes.string,
  tokenQuantity: PropTypes.string,
  tokenIcon: PropTypes.string,
  dollarValue: PropTypes.string,
};
