import designElementsViewInitialState from './designElementsViewInitialState';

const designElementsViewReducer = (
  state = designElementsViewInitialState,
  action,
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default designElementsViewReducer;
