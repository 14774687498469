import { ACTION_TYPES } from '../actionTypes';
import { initialBalances } from '../initialState/balances';
import { combineReducers } from 'redux';

const total = (state = initialBalances().total, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_TOTAL) {
    return action.payload;
  }
  return state;
};
const liquidEthereum = (state = initialBalances().liquidEthereum, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_LIQUID_ETHEREUM) {
    return action.payload;
  }
  return state;
};
const liquidPolygon = (state = initialBalances().liquidPolygon, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_LIQUID_POLYGON) {
    return action.payload;
  }
  return state;
};
const liquid = (state = initialBalances().liquid, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_LIQUID) {
    return action.payload;
  }
  return state;
};
const totalStaked = (state = initialBalances().totalStaked, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_TOTAL_STAKED) {
    return action.payload;
  }
  return state;
};
const stakedContract = (state = initialBalances().stakedContract, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_STAKED_CONTRACT) {
    return action.payload;
  }
  return state;
};
const stakedPool = (state = initialBalances().stakedPool, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_STAKED_POOL) {
    return action.payload;
  }
  return state;
};
const rewards = (state = initialBalances().rewards, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_REWARDS) {
    return action.payload;
  }
  return state;
};
const stakedContractUSD = (
  state = initialBalances().stakedContractUSD,
  action,
) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_STAKED_CONTRACT_USD) {
    return action.payload;
  }
  return state;
};
const stakedPoolUSD = (state = initialBalances().stakedPoolUSD, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_STAKED_POOL_USD) {
    return action.payload;
  }
  return state;
};
const apy = (state = initialBalances().apy, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_APY) {
    return action.payload;
  }
  return state;
};
const totalUSD = (state = initialBalances().totalUSD, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_TOTAL_USD) {
    return action.payload;
  }
  return state;
};
const liquidUSD = (state = initialBalances().liquidUSD, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_LIQUID_USD) {
    return action.payload;
  }
  return state;
};
const liquidUSDEthereum = (
  state = initialBalances().liquidUSDEthereum,
  action,
) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_LIQUID_USD_ETHEREUM) {
    return action.payload;
  }
  return state;
};
const liquidUSDPolygon = (
  state = initialBalances().liquidUSDPolygon,
  action,
) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_LIQUID_USD_POLYGON) {
    return action.payload;
  }
  return state;
};
const totalStakedUSD = (state = initialBalances().totalStakedUSD, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_TOTAL_STAKED_USD) {
    return action.payload;
  }
  return state;
};
const rewardsUSD = (state = initialBalances().rewardsUSD, action) => {
  if (action.type === ACTION_TYPES.BALANCE_SET_REWARDS_USD) {
    return action.payload;
  }
  return state;
};
const stakingContractTotalBalance = (
  state = initialBalances().stakingContractTotalBalance,
  action,
) => {
  if (action.type === ACTION_TYPES.STAKING_CONTRACT_TOTAL_BALANCE) {
    return action.payload;
  }
  return state;
};
const stakingContractTotalBalanceUSD = (
  state = initialBalances().stakingContractTotalBalanceUSD,
  action,
) => {
  if (action.type === ACTION_TYPES.STAKING_CONTRACT_TOTAL_BALANCE_USD) {
    return action.payload;
  }
  return state;
};

export default combineReducers({
  total,
  liquidEthereum,
  liquidPolygon,
  liquid,
  totalStaked,
  stakedContract,
  stakedPool,
  rewards,
  totalUSD,
  liquidUSD,
  liquidUSDEthereum,
  liquidUSDPolygon,
  stakedContractUSD,
  stakedPoolUSD,
  apy,
  totalStakedUSD,
  rewardsUSD,
  stakingContractTotalBalance,
  stakingContractTotalBalanceUSD,
});
