/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes, { func } from 'prop-types';
import { cnb } from 'cnbuilder';
import { TokenTile } from './convertTokenTile';
import { TransactionTile } from './convertTransactionTile';
import './convertTable.scss';
import ConvertMobileBalance from './convertMobileBalanceList';
import { ConvertMobileTransaction } from './convertMobileTransaction';
import cirusIcon from '../../../../images/cirusToken.png';
import ethereumIcon from '../../../../images/ethicon.png';
import maticIcon from '../../../../images/maticToken.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useWeb3React } from '@web3-react/core';
import {
  getPolygonTransactions,
  getEthereumTransactions,
} from '../../../../utils/api';
import { timeConverter, toFloat } from '../../../../utils/helpers';
import { injected } from '../../../../components/wallet/connectors';
const config = require('../../../../config');
const abiDecoder = require('abi-decoder');
const polygonABI = require('../../../../contracts/abis/CirusTokenPolygon.json');
const bridgeABI = require('../../../../contracts/abis/Bridge.json');

// eslint-disable-next-line react/prop-types
const ConvertBalanceHistory = ({
  balances,
  currencyConversion,
  maticPosClient,
  withdraw,
}) => {
  const [isTransactionsActive, setTransactionsActive] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const { account } = useWeb3React();

  // eslint-disable-next-line react/prop-types
  const { liquidEthereum, liquidPolygon, liquidUSDEthereum, liquidUSDPolygon } =
    balances;

  const getWithdraws = async () => {
    let tx = await getPolygonTransactions(account);
    console.log(tx);
    if (tx.transactions.status == '1') {
      abiDecoder.addABI(polygonABI);
      let withdrawalTransactions = [];
      let isCheckpointed, isDeposited;
      for (let index = 0; index < tx.transactions.result.length; index++) {
        if (
          tx.transactions.result[index].to ==
            config.tokenToAddressMap.cirus.matic.toLowerCase() &&
          tx.transactions.result[index].isError == '0'
        ) {
          const decodedData = abiDecoder.decodeMethod(
            tx.transactions.result[index].input,
          );
          try {
            isCheckpointed = await maticPosClient.isCheckPointed(
              tx.transactions.result[index].hash,
            );
            const erc20RootToken = maticPosClient.erc20(
              config.tokenToAddressMap['cirus'].ethereum,
              true,
            );
            isDeposited = await erc20RootToken.isWithdrawExited(
              tx.transactions.result[index].hash,
            );
          } catch (e) {
            console.log(e);
          }
          if (decodedData.name == 'withdraw') {
            withdrawalTransactions.push({
              action: decodedData.name,
              amount: decodedData.params[0].value / 10 ** 18,
              date: timeConverter(tx.transactions.result[index].timeStamp),
              isCheckpointed: isCheckpointed,
              isDeposited:
                typeof isDeposited === 'object' ? false : isDeposited,
              hash: tx.transactions.result[index].hash,
            });
          }
        }
      }
      console.log(withdrawalTransactions);
      return withdrawalTransactions;
    } else {
      return [];
    }
  };

  const getDeposits = async () => {
    let tx = await getEthereumTransactions(account);
    console.log(tx);
    abiDecoder.addABI(bridgeABI.abi);
    let depositTransactions = [];
    if (tx.transactions.status == '1') {
      for (let index = 0; index < tx.transactions.result.length; index++) {
        console.log(index);
        console.log(tx.transactions.result[index].to);
        console.log(config);
        if (
          tx.transactions.result[index].to ==
            config.root[
              process.env.REACT_APP_NETWORK_ETHEREUM
            ].POSRootChainManager.toLowerCase() &&
          tx.transactions.result[index].isError == '0'
        ) {
          console.log(tx.transactions.result[index].input);
          const decodedData = abiDecoder.decodeMethod(
            tx.transactions.result[index].input,
          );
          console.log(decodedData);
          if (decodedData.name == 'depositFor') {
            console.log(decodedData);
            depositTransactions.push({
              action: 'Deposit',
              amount:
                toFloat(decodedData.params[2].value.toString(16), 16) /
                10 ** 18,
              date: timeConverter(tx.transactions.result[index].timeStamp),
            });
          }
        }
      }
      console.log(depositTransactions);
      return depositTransactions;
    }
  };

  const init = async () => {
    let withdraws = await getWithdraws();
    let deposits = await getDeposits();
    let txs = withdraws.concat(deposits);
    txs.sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0));
    console.log(txs);
    setTransactions(txs);
  };

  useEffect(() => {
    if (account) {
      init();
    }
  }, [account]);

  return (
    <div>
      <div className="standard-table tabs mb-0">
        <ul>
          <li
            className={cnb(
              { 'is-active': !isTransactionsActive },
              'convert-tab',
            )}
          >
            {/* href linter disabled at top; bulma requires a tag for tabs components */}
            <a className="" onClick={() => setTransactionsActive(false)}>
              Tokens
            </a>
          </li>
          <li className={cnb({ 'is-active': isTransactionsActive })}>
            <a className="" onClick={() => setTransactionsActive(true)}>
              Transactions
            </a>
          </li>
        </ul>
      </div>
      <div className="">
        {/* Tokens Display */}
        {!isTransactionsActive && (
          <div>
            <ConvertMobileBalance />
            <table className="standard-table convert table is-fullwidth is-hidden-mobile">
              {/* HEADER */}
              <thead>
                <th className="is-narrow" />
                <th className="is-narrow convert-table-type">
                  <abbr title="Type" className="dull is-vcentered">
                    Type
                  </abbr>
                </th>
                <th />
                <th className="is-narrow">
                  <abbr title="Amount" className="dull">
                    Amount
                  </abbr>
                </th>
                <th className="is-narrow">
                  <abbr title="Value" className="dull">
                    Value
                  </abbr>
                </th>
                {/* <th className="is-narrow">
                  <abbr title="Action" className="dull">
                    Action
                  </abbr>
                </th> */}
                <th className="is-narrow" />
                <th className="is-narrow" />
              </thead>
              {/* VALUES */}
              <tbody>
                <TokenTile
                  tokenName={'Cirus ERC-20'}
                  tokenNameShort={'CIRUS'}
                  tokenQuantity={liquidEthereum}
                  tokenIcon={cirusIcon}
                  dollarValue={liquidUSDEthereum}
                />
                <TokenTile
                  tokenName={'Cirus Polygon'}
                  tokenNameShort={'CIRUS'}
                  tokenQuantity={liquidPolygon}
                  tokenIcon={cirusIcon}
                  dollarValue={liquidUSDPolygon}
                />
                {/*
                <TokenTile
                  tokenName={'Ethereum ERC-20'}
                  tokenNameShort={'ETH'}
                  tokenQuantity={'5.00'}
                  tokenIcon={ethereumIcon}
                  dollarValue={'20,000.00'}
                />
                <TokenTile
                  tokenName={'Matic Token'}
                  tokenNameShort={'MATIC'}
                  tokenQuantity={'5.00'}
                  tokenIcon={maticIcon}
                  dollarValue={'20,000.00'}
                />
                */}
              </tbody>
            </table>
          </div>
        )}
        {/* Transaction History Display */}
        {isTransactionsActive && (
          <div>
            <ConvertMobileTransaction transactions={transactions} />
            <table className="standard-table convert table is-fullwidth is-hidden-mobile">
              <thead>
                <th className="is-narrow" />
                <th className="is-narrow convert-table-type">
                  <abbr title="Type" className="dull">
                    Type
                  </abbr>
                </th>
                <th />
                <th />
                <th className="">
                  <abbr title="Action" className="dull">
                    Action
                  </abbr>
                </th>
                <th>
                  <abbr title="Amount" className="dull">
                    Amount
                  </abbr>
                </th>
                <th className="">
                  <abbr title="Value" className="dull">
                    Value
                  </abbr>
                </th>
                <th className="">
                  <abbr title="Date" className="dull">
                    Date
                  </abbr>
                </th>
                <th className="">
                  <abbr title="Status" className="dull">
                    Status
                  </abbr>
                </th>
                <th className="is-narrow" />
              </thead>
              {/* VALUES */}
              <tbody>
                {console.log('transactions', transactions)}
                {transactions.map((t, key) => {
                  return (
                    <TransactionTile
                      key={key}
                      tokenName={'Cirus ERC-20'}
                      tokenNameShort={'CIRUS'}
                      tokenQuantity={t.amount}
                      tokenIcon={cirusIcon}
                      dollarValue={t.amount * currencyConversion.USDToCirus}
                      action={t.action}
                      isCheckpointed={t.isCheckpointed}
                      isDeposited={t.isDeposited}
                      date={t.date}
                      withdraw={withdraw}
                      hash={t.hash}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

ConvertBalanceHistory.propTypes = {
  liquidEthereum: PropTypes.number,
  liquidPolygon: PropTypes.number,
  liquidUSDEthereum: PropTypes.number,
  liquidUSDPolygon: PropTypes.number,
  withdraw: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    balances: state.balances,
    currencyConversion: state.currencyConversion,
  };
};
export default connect(mapStateToProps, (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
}))(ConvertBalanceHistory);
