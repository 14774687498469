import React from 'react';
import { PropTypes } from 'prop-types';

export const MantraConfirmingUnstake = ({ action, amount }) => {
  return (
    <div className="has-text-centered mb-6 mr-6 ml-6">
      <i className="fas fa-circle-notch fa-7x fa-spin has-text-primary" />
      <p className="title is-2 mt-6 mb-5">Confirming</p>
      <p className="has-text-weight-bold">
        <small>You are {action}</small>
      </p>
      <p className="subtitle is-5 has-text-weight-bold">{amount} CIRUS</p>
    </div>
  );
};

MantraConfirmingUnstake.propTypes = {
  action: PropTypes.string,
  amount: PropTypes.number,
};
