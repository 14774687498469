import React, { useState } from 'react';
import AmountBox from '../../../../components/amountBox';
import { PropTypes } from 'prop-types';
import './mantraStakingForm.scss';
import '../../../../styles/colorPallete.scss';
import cirusToken from '../../../../images/cirusToken.png';
import { useWeb3React } from '@web3-react/core';

export const MantraStakingForm = ({
  cirusBalance,
  toggleIsMantraStakingModalActive,
  setAmountToStake,
  amountToStake,
  stakedAmount,
}) => {
  const setAmount = (percentage) => {
    setAmountToStake(
      parseInt((percentage / 100) * cirusBalance * 10000) / 10000,
    );
  };
  return (
    <div>
      <div className="columns is-centered mb-5">
        <div className="column is-4">
          <AmountBox middleTxt="Cirus" avatar={cirusToken} />
        </div>
      </div>
      <div className="columns balance-stack-statistics">
        <div className="column is-borderless">
          <AmountBox
            topTxt="Your Balance"
            middleTxt={`${cirusBalance} CIRUS`}
            className="has-text-centered"
          />
        </div>
        <div className="column">
          <AmountBox
            topTxt="Current Total Stake"
            middleTxt={`${stakedAmount} CIRUS`}
            className="has-text-centered"
          />
        </div>
      </div>

      <div className="ml-6 mt-6 mr-6 mb-6">
        <div className="field has-addons fixed-right-addon">
          <p className="control">
            <span className="button is-static is-large is-rounded background-cirus-grey">
              <img src={cirusToken} alt="Cirus Token" width="80" />
            </span>
          </p>
          <p className="control is-expanded">
            <input
              type="text"
              className="input is-large is-rounded"
              placeholder="Stake amount"
              value={amountToStake}
              onChange={(e) => setAmountToStake(e.target.value)}
            />
          </p>
        </div>

        <div className="columns is-centered has-text-centered mx-0 mt-5 mantra-staking-form">
          <div className="column p-0">
            <button
              className="button is-primary is-outlined is-wide-button is-rounded has-text-centered"
              onClick={(e) => setAmount(25)}
            >
              25%
            </button>
          </div>
          <div className="column p-0">
            <button
              className="button is-primary is-outlined is-wide-button is-rounded has-text-centered"
              onClick={(e) => setAmount(50)}
            >
              50%
            </button>
          </div>
          <div className="column p-0">
            <button
              className="button is-primary is-outlined is-wide-button is-rounded has-text-centered"
              onClick={(e) => setAmount(75)}
            >
              75%
            </button>
          </div>
          <div className="column p-0">
            <button
              className="button is-primary is-outlined is-wide-button is-rounded has-text-centered"
              onClick={(e) => setAmountToStake(cirusBalance)}
            >
              Full
            </button>
          </div>
        </div>

        <p className="has-text-centered has-text-grey has-text-weight-bold">
          <small>Minimum stake 1 Cirus</small>
        </p>
      </div>
      <hr />
      <div className="columns ml-6 mr-6 mt-4">
        <div className="column has-text-weight-bold">Estimated APY:</div>
        <div className="column has-text-weight-bold has-text-right">30%</div>
      </div>
      <div className="columns ml-6 mr-6">
        <div className="column has-text-weight-bold">
          Estimated Daily Rewards:
        </div>
        <div className="column has-text-weight-bold has-text-right">
          0 Cirus
        </div>
      </div>
      <p className="has-text-centered mt-6 mb-6">
        <button
          className="button is-primary is-large is-rounded"
          onClick={() => toggleIsMantraStakingModalActive('stakeNow')}
        >
          Continue to Stake
        </button>
      </p>
    </div>
  );
};
MantraStakingForm.propTypes = {
  cirusBalance: PropTypes.number,
  toggleIsMantraStakingModalActive: PropTypes.func,
  setAmountToStake: PropTypes.func,
  stakedAmount: PropTypes.number,
  amountToStake: PropTypes.number,
};
