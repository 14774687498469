import React from 'react';

const CheckSignGreen = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 0C16.1177 0 0 16.1177 0 36C0 55.8823 16.1177 72 36 72C55.8823 72 72 55.8823 72 36C72 26.4522 68.2072 17.2955 61.4558 10.5442C54.7045 3.79285 45.5478 0 36 0ZM30.8571 50.3763L18 37.5192L22.0901 33.4286L30.8571 42.1951L49.9114 23.1429L54.0147 27.2209L30.8571 50.3763Z"
        fill="#2CB700"
      />
      <path
        opacity="0.01"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8526 50L18 37.2533L22.0897 33.1973L30.8526 41.888L49.9 23L54 27.0407L30.8526 50V50Z"
        fill="black"
      />
    </svg>
  );
};

export default CheckSignGreen;
