import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import reportWebVitals from './reportWebVitals';
import Router from './router';
import configureStore from './store/configureStore';
import * as env from './env';
import GlobalBlockingSpinner from './components/globalBlockingSpinner/globalBlockingSpinner';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Helmet } from 'react-helmet';

const store = configureStore();

function getLibrary(provider) {
  return new Web3Provider(provider);
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <Router />
        <GlobalBlockingSpinner />
        <Toaster
          position="top-right"
          toastOptions={{
            duration: env.notificationTimeOut,
            style: {
              border: '1px solid rgb(0 161 255)',
              padding: '16px',
              color: 'rgb(0 119 189)',
            },
          }}
        />
      </Provider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();