import React from 'react';
import PropTypes from 'prop-types';
import './exampleModal.scss';

const ExampleModal = (props) => {
  const { isActive, onClose } = props;

  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="box">
          <div className="columns">
            <div className="column is-2">
              <figure className="image is-96x96">
                <img src="notificationModalHeaderIcon.svg" alt="Notification" />
              </figure>
            </div>
            <div className="column mt-5">
              <p className="title is-4">Notification</p>
            </div>
          </div>

          <div className="notification is-tight">
            You’ve logged in for <strong>3</strong> consecutive days,
            <strong>5</strong> more days and you’ll increase your multiplier.
          </div>
          <div className="notification is-tight">
            Your reward account grew by <strong>%3</strong> or&nbsp;
            <strong>$40</strong>
          </div>
          <button
            className="modal-close-button"
            aria-label="close"
            title="Close"
            onClick={onClose}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

ExampleModal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ExampleModal;
