import React from 'react';
import './globalBlockingSpinner.scss';

const GlobalBlockingSpinner = () => {
  return (
    <div className={`global-blocking-spinner is-hidden`}>
      <img src="" className="fa-spin" alt="Loading Spinner" />
    </div>
  );
};

export default GlobalBlockingSpinner;
