import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { throttle } from 'lodash';
import { setMobileTableExpanded } from './../../store/actions/mobileTableActions';
import AmountBox from '../amountBox';
import totalIcon from '../../images/Cirus Dash Icons/1TotalBalance@4x.png';
import liquidIcon from '../../images/Cirus Dash Icons/2LiquidBalance@4x.png';
import stakedIcon from '../../images/Cirus Dash Icons/3StakedBalance@4x.png';
import rewardIcon from '../../images/Cirus Dash Icons/4RewardBalance@4x.png';
import multiplierIcon from '../../images/Cirus Dash Icons/5MultiplierAmount@4x.png';
import './navbarTopTable.scss';

const NavbarTopTable = ({ balances, isExpanded }) => {
  const {
    total,
    totalUSD,
    liquid,
    liquidUSD,
    rewards,
    rewardsUSD,
    stakingContractTotalBalance,
    stakingContractTotalBalanceUSD,
  } = balances;

  const [isVisible, setIsVisible] = useState(false);
  const [scrolledInfoTableHeight, setScrolledInfoTableHeight] = useState(81);
  const scrolledInfoTable = useRef(null);

  const topTableElement = document.getElementsByClassName('topTableElement');

  const onScroll = throttle(() => {
    if (!topTableElement[0]) {
      setIsVisible(false);
      return;
    }
    const height = topTableElement[0].getBoundingClientRect().height;
    const top = topTableElement[0].getBoundingClientRect().top;
    setIsVisible(/*height + */ top - scrolledInfoTableHeight < 0);
  }, 200);

  useLayoutEffect(() => {
    if (scrolledInfoTable.current.clientHeight > 0) {
      setScrolledInfoTableHeight(scrolledInfoTable.current.clientHeight);
    }
  }, [isVisible]);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  }, [isExpanded]);

  return (
    <div
      className={`columns navbar-top-table-content m-0
        ${isVisible ? '' : 'is-hidden'}
      `}
      ref={scrolledInfoTable}
    >
      <div>
        <div className="column navbar-top-table-item">
          <AmountBox
            topTxt="Total Balance"
            middleTxt={`${new Intl.NumberFormat().format(total)} CIRUS`}
            bottomTxt={`$${new Intl.NumberFormat().format(totalUSD)} USD`}
            avatar={totalIcon}
            className="navbar-top-table-box"
          />
        </div>
      </div>
      <div>
        <div className="column navbarTopTable-item">
          <AmountBox
            topTxt="Liquid Balance"
            middleTxt={`${new Intl.NumberFormat().format(liquid)} CIRUS`}
            bottomTxt={`$${new Intl.NumberFormat().format(liquidUSD)} USD`}
            avatar={liquidIcon}
            className="navbar-top-table-box"
          />
        </div>
      </div>
      <div className="is-hidden-tablet-only">
        <div className="column navbarTopTable-item">
          <AmountBox
            topTxt="Staked Balance"
            middleTxt={`${new Intl.NumberFormat().format(stakingContractTotalBalance)} CIRUS`}
            bottomTxt={`$${new Intl.NumberFormat().format(stakingContractTotalBalanceUSD)} USD`}
            avatar={stakedIcon}
            className="navbar-top-table-box"
          />
        </div>
      </div>
      <div className="is-hidden-desktop-only is-hidden-tablet-only">
        <div className="column navbarTopTable-item">
          <AmountBox
            topTxt="Reward Balance"
            middleTxt={`${new Intl.NumberFormat().format(rewards)} CIRUS`}
            bottomTxt={`$${new Intl.NumberFormat().format(rewardsUSD)} USD`}
            avatar={rewardIcon}
            className="navbar-top-table-box"
          />
        </div>
      </div>
      {/* <div className="is-hidden-widescreen-only is-hidden-desktop-only is-hidden-tablet-only">
        <div className="column navbarTopTable-item">
          <AmountBox
            topTxt="Multiplier Amount"
            middleTxt="0.5x"
            avatar={multiplierIcon}
            className="navbar-top-table-box"
          />
        </div>
      </div> */}
    </div>
  );
};

NavbarTopTable.propTypes = {
  total: PropTypes.number,
  totalUSD: PropTypes.number,
  liquid: PropTypes.number,
  liquidUSD: PropTypes.number,
  rewards: PropTypes.number,
  rewardsUSD: PropTypes.number,
  totalStaked: PropTypes.number,
  totalStakedUSD: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    balances: state.balances,
    isExpanded: state.mobileTable.isExpanded,
  };
};
export default connect(mapStateToProps, (dispatch) => ({
  dispatch,
  ...bindActionCreators({ setMobileTableExpanded }, dispatch),
}))(NavbarTopTable);
