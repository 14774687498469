import React from 'react';
import PropTypes from 'prop-types';
import AmountBox from '../../../../components/amountBox';
import cirusToken from '../../../../images/cirusToken.png';
import './mantraStakeModal.scss';
import { MantraStakeNow } from './mantraModalFlow/mantraStakeNow';
import { MantraStakeAmount } from './mantraModalFlow/mantraStakeAmount';
import { MantraWithdrawTimer } from './mantraModalFlow/mantraWithdrawTimer';
import { MantraUnstaking } from './mantraModalFlow/mantraUnstaking';
import { MantraConfirmingUnstake } from './mantraModalFlow/mantraConfirmingUnstake';
import { MantraConfirmedUnstake } from './mantraModalFlow/mantraConfirmedUnstake';

const MantraStakeModal = ({
  step,
  amount,
  setAmountToStake,
  setStep,
  stake,
  preApprove,
  poolApproved,
  isApproving,
  isStaking,
  action,
  stakedAmount,
  cirusBalance,
  tx,
}) => {
  return (
    <>
      <div className="columns is-centered mt-6 mb-6">
        <div className="column is-4 ml-6">
          <AmountBox middleTxt="Cirus" avatar={cirusToken} />
        </div>
      </div>
      {step == 'stakeAmount' ? (
        <MantraStakeAmount
          cirusToken={cirusToken}
          amount={amount}
          setStep={setStep}
          stakedAmount={stakedAmount}
          cirusBalance={cirusBalance}
          setAmountToStake={setAmountToStake}
        />
      ) : step == 'stakeNow' ? (
        <MantraStakeNow
          cirusToken={cirusToken}
          amount={amount}
          stake={stake}
          preApprove={preApprove}
          poolApproved={poolApproved}
          isApproving={isApproving}
        />
      ) : step == 'withdrawTimer' ? (
        <MantraWithdrawTimer />
      ) : step == 'unstaking' ? (
        <MantraUnstaking />
      ) : step == 'confirm' ? (
        <MantraConfirmingUnstake
          isStaking={isStaking}
          amount={amount}
          action={action}
          setStep={setStep}
        />
      ) : step == 'confirmedUnstake' ? (
        <MantraConfirmedUnstake action={action} amount={amount} tx={tx} />
      ) : null}
    </>
  );
};

MantraStakeModal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  amount: PropTypes.number,
  setAmountToStake: PropTypes.func,
  step: PropTypes.string,
  setStep: PropTypes.func,
  stake: PropTypes.func,
  preApprove: PropTypes.func,
  poolApproved: PropTypes.bool,
  isApproving: PropTypes.bool,
  isStaking: PropTypes.bool,
  action: PropTypes.string,
  stakedAmount: PropTypes.number,
  cirusBalance: PropTypes.number,
  tx: PropTypes.string,
};

export default MantraStakeModal;
