import React from 'react';
import AmountBox from '../../../../components/amountBox';
import './mantraAllBalance.scss';
import cirusToken from '../../../../images/cirusToken.png';
import maticToken from '../../../../images/maticToken.png';
import StateIndicator from '../../../../components/stateIndicator/stateIndicator';
import { PropTypes } from 'prop-types';

export const MantraAllBalance = ({ stakedPool }) => {
  return (
    <table className="table is-fullwidth standard-table mantra-table is-hidden-touch">
      <thead>
        <th className="is-narrow" />
        <th className="is-narrow">Staked Amount</th>
        <th>Status</th>
        <th>Est. APY</th>
        <th>Est. Daily Rewards</th>
        <th className="is-narrow">Earned Rewards</th>
        <th />
      </thead>
      <tbody>
        <tr className="is-active">
          <th />
          <td>
            <AmountBox
              middleTxt="Cirus"
              bottomTxt={stakedPool}
              avatar={cirusToken}
            />
          </td>
          <td>
            {stakedPool > 0 ? (
              <StateIndicator
                state="is-active"
                classNames={'small has-text-weight-semibold'}
              >
                Active
              </StateIndicator>
            ) : (
              '--'
            )}
          </td>
          <td>30%</td>
          <td>0 CIRUS</td>
          <td>0 CIRUS</td>
          <td />
        </tr>
        {/*<tr>
          <th />
          <td>
            <AmountBox
              middleTxt="Cirus / Matic"
              bottomTxt="$0.00"
              avatar={maticToken}
            />
          </td>
          <td>--</td>
          <td>30%</td>
          <td>0 CIRUS</td>
          <td>0 CIRUS</td>
          <td />
        </tr>*/}
      </tbody>
    </table>
  );
};
MantraAllBalance.propTypes = {
  stakedPool: PropTypes.number,
};
