import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './convertTransfer.scss';
import { ConvertMoveFundsModal } from './convertMoveFunds/convertMoveFundsModal';
import ethereumIcon from '../../../images/ethicon.png';
import swapIcon from '../../../images/swapIcon.png';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { getNetworkName } from '../../../utils/helpers';
import { useFullscreen } from 'react-use';

const config = require('../../../config');

// eslint-disable-next-line react/prop-types
const ConvertTransfer = ({ balances, maticPosClient }) => {
  const [isMoveModalActive, setMoveModalActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [amount, setAmount] = useState(0);
  const [modal, setModal] = useState('overview1');
  const [modalTitle, setModalTitle] = useState('');
  const [depositTx, setDepositTx] = useState();
  const [metamaskNetworkName, setMetamaskNetworkName] = useState();
  const [transferToNetworkName, setTransferToNetworkName] = useState();
  const [metamaskLiquidBalance, setMetamaskLiquidBalance] = useState();
  const [transferToLiquidBalance, setTransferToLiquidBalance] = useState();
  const { active, account, library, chainId } = useWeb3React();
  // eslint-disable-next-line react/prop-types
  const { total, liquidEthereum, liquidPolygon, liquidUSD, rewards, staked } =
    balances;
  const setModalOverview2 = () => {
    setModal('overview2');
  };
  const transferToPolygon = async (event) => {
    if (amount <= 0) return;
    setLoading(true);
    setErrorMsg('');

    const tokenAddress = config.tokenToAddressMap['cirus'].ethereum;
    const amountToTransfer = ethers.utils.parseEther(amount).toString();
    try {
      setModal('loading');
      setModalTitle('Confirm permission on Metamask');

      const erc20RootToken = maticPosClient.erc20(tokenAddress, true);
      const approveResult = await erc20RootToken.approve(amountToTransfer);
      await approveResult.getTransactionHash();
      setModalTitle('Approve bridge contract...');
      await approveResult.getReceipt();
      setModalTitle('Approve transfer on Metamask');
      setModal('review');
    } catch (err) {
      setMoveModalActive(false);
      setModal('overview1');
      console.log(err);
      setErrorMsg(err.message);
    }

    setLoading(false);
  };

  const transferToPolygon2 = async (event) => {
    if (amount <= 0) return;
    setLoading(true);
    setErrorMsg('');

    const tokenAddress = config.tokenToAddressMap['cirus'].ethereum;
    const amountToTransfer = ethers.utils.parseEther(amount).toString();
    console.log('amountToTransfer', amountToTransfer);
    try {
      setModal('loading');
      setModalTitle('Approve transfer on Metamask');

      const erc20RootToken = maticPosClient.erc20(tokenAddress, true);
      const result = await erc20RootToken.deposit(amountToTransfer, account);
      const depositTxHash = await result.getTransactionHash();
      setDepositTx(depositTxHash);
      setModal('progress');
    } catch (err) {
      setMoveModalActive(false);
      setModal('overview1');
      console.log(err);
      setErrorMsg(err.message);
    }

    setLoading(false);
  };

  const transferToEthereum = async (event) => {
    if (amount <= 0) return;

    const tokenAddress = config.tokenToAddressMap['cirus'].matic;
    const amountToTransfer = ethers.utils.parseEther(amount).toString();

    try {
      setModal('loading');
      setModalTitle('Confirm permission on Metamask');
      console.log('tokenAddress', tokenAddress);
      const erc20Token = maticPosClient.erc20(tokenAddress);
      const result = await erc20Token.withdrawStart(amountToTransfer);
      const txHash = await result.getTransactionHash();
      const txReceipt = await result.getReceipt();
      setMoveModalActive(false);
    } catch (e) {
      console.log(e);
    }
  };

  const setBalances = () => {
    setMetamaskLiquidBalance(
      chainId == process.env.REACT_APP_NETWORK_ETHEREUM
        ? liquidEthereum
        : liquidPolygon,
    );
    setTransferToLiquidBalance(
      chainId == process.env.REACT_APP_NETWORK_ETHEREUM
        ? liquidPolygon
        : liquidEthereum,
    );
  };

  const changeNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.enable();
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId:
                chainId == process.env.REACT_APP_NETWORK_ETHEREUM
                  ? '0x' +
                    Number(process.env.REACT_APP_NETWORK_POLYGON).toString(16)
                  : '0x' +
                    Number(process.env.REACT_APP_NETWORK_ETHEREUM).toString(16),
            },
          ],
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    setMetamaskNetworkName(getNetworkName(chainId));
    if (chainId == process.env.REACT_APP_NETWORK_ETHEREUM) {
      setTransferToNetworkName(
        getNetworkName(parseInt(process.env.REACT_APP_NETWORK_POLYGON)),
      );
    } else {
      setTransferToNetworkName(
        getNetworkName(parseInt(process.env.REACT_APP_NETWORK_ETHEREUM)),
      );
    }

    setBalances();
  }, [chainId, liquidEthereum, liquidPolygon]);

  useEffect(() => {
    if (window.ethereum) {
    window.ethereum.on('message', (message) => {
      //console.log(message);
    });
  }
  }, []);

  return (
    <div className="card-content p-0">
      {/* transfer origin div */}
      <div className="transfer-origin rp-6 rpb-0">
        <div className="columns column is-mobile is-vcentered m-0 rpt-0 rpb-0">
          <label className="">
            <div className="columns is-mobile level mb-5">
              <div className="column is-narrow level-left rp-0">
                <div className="transfer label level-item is-narrow is-size-6 rpt-0 rpr-6 rpb-0 rpl-6">
                  <p className="">From</p>
                </div>
              </div>
              <div className="column is-narrow level-left">
                <h2 className="level-item title-smaller">
                  {metamaskNetworkName}
                </h2>
              </div>
              <div className="column" />
            </div>
          </label>
        </div>
        <div className="absolute-container field has-addons fixed-left-addon">
          <p className="control has-icons-left is-expanded">
            <input
              className="transfer-input input is-rounded is-large"
              placeholder="Amount"
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
            />
            <span className="transfer-input-icon level level-item is-mobile icon is-left">
              <figure className="">
                <img alt="Ethereum Icon" src={ethereumIcon} />
              </figure>
            </span>
          </p>
          {/* Desktop Full Button */}
          <button
            className="transfer full button is-outlined is-primary is-rounded is-hidden-touch"
            onClick={(e) => setAmount(metamaskLiquidBalance)}
          >
            Full
          </button>
          <p className="control">
            <div className="select is-large">
              <select className="transfer-input input is-rounded is-large transfer coin-select is-size-6-mobile">
                <option value="CIRUS" className="">
                  CIRUS
                </option>
              </select>
            </div>
          </p>
        </div>
        {/* mobile Full Button */}
        <div className="columns column mt-1 is-hidden-desktop">
          <button className="transfer full button is-outlined is-primary is-rounded">
            Full
          </button>
        </div>
        <div className="columns is-mobile rpt-3 eth-balance">
          <div className="column">
            <p>Value </p>
            <p className="has-text-weight-semibold">
              $
              {new Intl.NumberFormat().format(parseFloat(liquidUSD).toFixed(2))}
            </p>
          </div>
          <div className="column is-narrow">
            <button className="swap button is-round translate-down box is-borderless">
              <img
                alt="swap button"
                src={swapIcon}
                className="swap-image"
                onClick={(e) => changeNetwork()}
              />
            </button>
          </div>
          <div className="column">
            <p className="has-text-right">
              <span className="is-hidden-touch">Available</span> Balance
            </p>
            <p className="has-text-right is-hidden-touch has-text-weight-semibold">
              {`${new Intl.NumberFormat().format(
                parseFloat(metamaskLiquidBalance).toFixed(2),
              )} Cirus`}
            </p>
            <p className="has-text-right is-hidden-desktop has-text-weight-semibold">
              {new Intl.NumberFormat().format(
                parseFloat(metamaskLiquidBalance).toFixed(2),
              )}
            </p>
            <p className="has-text-right is-hidden-desktop pb-0 has-text-weight-semibold">
              Cirus
            </p>
          </div>
        </div>
      </div>
      {/* Transfer Destination div */}
      <div className="transfer-destination">
        <div className="columns is-mobile is-vcentered m-0 rpt-5 rpb-3 rpr-6 rpl-6">
          <div className="column">
            <label>
              <div className="columns is-mobile level mb-1">
                <div className="column is-narrow level-left rp-0">
                  <div className="transfer label level-item is-narrow is-size-6 rpt-0 rpr-6 rpb-0 rpl-6">
                    <p className="">To</p>
                  </div>
                </div>
                <div className="column is-narrow level-left">
                  <p className="level-item title-smaller">
                    {transferToNetworkName}
                  </p>
                </div>
                <div className="column" />
              </div>
            </label>
          </div>
          {/* <div className="column is-5" /> */}
          <div className="column pr-0">
            <p className="has-text-right">
              <span className="is-hidden-touch">Available</span> Balance
            </p>
            <p className="has-text-right is-hidden-touch has-text-weight-semibold">
              {`${new Intl.NumberFormat().format(
                parseFloat(transferToLiquidBalance).toFixed(2),
              )} Cirus`}
            </p>
            <p className="has-text-right is-hidden-desktop has-text-weight-semibold">
              {transferToLiquidBalance}
            </p>
            <p className="has-text-right is-hidden-desktop has-text-weight-semibold">
              Cirus
            </p>
          </div>
        </div>
        <hr className="m-0" />
        <div className="columns is-mobile column is-centered mt-5 mb-2 rpr-6 rpl-6">
          <p className="has-text-weight-semibold">Transfer Mode: PoS Bridge</p>
        </div>
        <div className="columns is-mobile column is-centered rpr-6 rpl-6 mb-0">
          <button
            className="transfer button is-primary is-rounded is-large"
            type="button"
            value="Proceed to Transfer"
            onClick={() => setMoveModalActive(true)}
          >
            Proceed to Transfer
          </button>
          <ConvertMoveFundsModal
            modal={modal}
            modalTitle={modalTitle}
            isActive={isMoveModalActive}
            onClose={() => setMoveModalActive(false)}
            onTransfer={
              process.env.REACT_APP_NETWORK_ETHEREUM == chainId
                ? () => transferToPolygon()
                : () => transferToEthereum()
            }
            onTransfer2={
              process.env.REACT_APP_NETWORK_ETHEREUM == chainId
                ? () => transferToPolygon2()
                : () => transferToEthereum()
            }
            onsetModalOverview2={() => setModalOverview2()}
            amount={amount}
            depositTx={depositTx}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    balances: state.balances,
  };
};
export default connect(mapStateToProps, (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
}))(ConvertTransfer);
