import React from 'react';
import { MakeMobileTableTile } from '../../../../components/tables/makeMobileTableTile';
import { PropTypes } from 'prop-types';
import cirusToken from '../../../../images/cirusToken.png';
import maticToken from '../../../../images/maticToken.png';

export const MantraMobileAllBalance = ({
  toggleIsMantraStakingModalActive,
  stakedPool,
}) => {
  // create mobile tile with titles / button true / button content stake
  const MantraMobileTableTile = MakeMobileTableTile(
    'status',
    'Est. APY',
    'Est. Daily Rewards',
    'Earned Rewards',
    true,
    'Stake',
  );
  return (
    <div className="is-hidden-desktop">
      <MantraMobileTableTile
        tokenName={'Cirus'}
        tokenIcon={cirusToken}
        firstRowRight={stakedPool}
        secondRowLeftContent={'--'}
        secondRowRightContent={'30%'}
        thirdRowLeftContent={'0 CIRUS'}
        thirdRowRightContent={'0 CIRUS'}
        buttonFunction={() => {
          toggleIsMantraStakingModalActive();
        }}
      />
      {/*<MantraMobileTableTile
        tokenName={'Cirus'}
        tokenIcon={maticToken}
        firstRowRight={'$0.00'}
        secondRowLeftContent={'--'}
        secondRowRightContent={'30%'}
        thirdRowLeftContent={'0 CIRUS'}
        thirdRowRightContent={'1 CIRUS'}
        buttonFunction={() => {
          toggleIsMantraStakingModalActive();
        }}
      />*/}
    </div>
  );
};
MantraMobileAllBalance.propTypes = {
  toggleIsMantraStakingModalActive: PropTypes.func,
  stakedPool: PropTypes.number,
};
