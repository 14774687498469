import React from 'react';
import { PropTypes } from 'prop-types';

export const MantraStakeNow = ({
  amount,
  stake,
  preApprove,
  poolApproved,
  isApproving,
}) => {
  return (
    <>
      <div className="has-text-centered mb-6 mr-6 ml-6">
        <div className="notification pt-5 mb-6">
          <div className="columns ml-6 mr-6 pt-4">
            <div className="column has-text-weight-bold has-text-left">
              You are Staking:
            </div>
            <div className="column has-text-weight-bold has-text-right">
              {amount} Cirus
            </div>
          </div>
          <div className="columns ml-6 mr-6 pb-4">
            <div className="column has-text-weight-bold has-text-left">
              Estimated APY:
            </div>
            <div className="column has-text-weight-bold has-text-right">
              30%
            </div>
          </div>
        </div>
        <p className="mt-3 has-text-left">
          <span className="has-text-primary">*</span> Staking will start
          immediately after clicking “Stake Now”
        </p>
        <p className="mt-3 mb-6 has-text-left">
          <span className="has-text-primary">*</span> Unlike some other staked
          assets, there is no set duration for the staking of OM You may unstake
          your tokens at any time without penalties. There is an 8 day unstaking
          period you must wait before withdrawing your staked tokens.
        </p>
        <p>
          {isApproving ? (
            <button
              className="button is-primary is-large is-rounded"
              style={{
                paddingLeft: 'calc(1em + 0.25em)',
                paddingRight: 'calc(1em + 0.25em)',
              }}
            >
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </button>
          ) : (
            <button
              className="button is-primary is-rounded is-large"
              disabled={poolApproved ? true : false}
              onClick={(e) => preApprove()}
            >
              Preapprove
            </button>
          )}
          <button
            className="button is-primary is-rounded is-large"
            disabled={poolApproved ? false : true}
            onClick={(e) => (poolApproved ? stake() : null)}
          >
            Stake Now
          </button>
        </p>
      </div>
    </>
  );
};
MantraStakeNow.propTypes = {
  amount: PropTypes.number,
  stake: PropTypes.func,
  preApprove: PropTypes.func,
  poolApproved: PropTypes.bool,
  isApproving: PropTypes.bool,
};
