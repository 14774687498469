export const initialNotifications = () => [
  {
    content: 'Nice job! Your reward account grew by X%',
    date: 'Today',
  },
  {
    content: 'Way to go, you are now in tier X (1-4)',
    date: '1D ago',
  },
  {
    content: 'Keep it going, you now have X points and X referrals',
    date: '1M ago',
  },
  {
    content: 'Guess what? $xxxxx was traded across the Cirus network today',
    date: '1M ago',
  },
  {
    content: 'Keep inviting friends! Someone used your referral code on [date]',
    date: '2M ago',
  },
];
