import React from 'react';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from '../../utils/helpers';
import Divider from '../divider/divider';
import cirusToken from '../../images/cirusToken.png';
import './signTransactionModal.scss';

const SignTransactionModal = (props) => {
  const {
    isActive,
    onClose,
    amountToStake,
    stake,
    unstake,
    action,
    withdraw,
    preApprove,
    contractApproved,
    isApproving,
  } = props;

  const amount = new Intl.NumberFormat('en-IN').format((amountToStake * 1000) / 1000);

  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <button
          className="modal-close-button"
          aria-label="close"
          title="Close"
          onClick={onClose}
        >
          <i className="fa fa-times"></i>
        </button>
        <div className="box signTransactionBox">
          <div className="mt-5 mb-2">
            <p className="title is-4">Please Sign Transaction</p>
            <p className="textCenter">
              Please authorize the staking contract to interact with your wallet
              to continue
            </p>
          </div>
          {
            // action !== 'Unstake' &&
            // action !== 'Full Unstake' &&
            // action !== 'Claim Reward' ?
            <div className="notification is-tight">
              <div className="p-4">
                <p className="notification-title">
                  {capitalizeFirstLetter(action)} Amount
                </p>
                <div className="notification-amount">
                  <img alt="Icon: Cirus Token" src={cirusToken} width="50" />
                  <strong>{amount} CIRUS</strong>
                </div>
              </div>
              <Divider />
              <div className="p-4 mb-1">
                {action === 'Unstake' ||
                action === 'Full Unstake' ||
                action === 'Unstake and claim rewards' ? (
                  <p>
                    You are about to unstake your tokens. You will no longer
                    earn rewards on these tokens. <br />
                    By unstaking any amount of tokens, you will also be claiming
                    <strong> all of your earned rewards</strong>
                  </p>
                ) : action === 'Claim Reward' ? (
                  <span className="mt-4 ">
                    You are about to claim earned rewards. <br />
                    This will not affect your staked balance
                  </span>
                ) : (
                  <span className="mt-4 ">
                    <strong className="cirus-red">15%</strong> of your tokens
                    will be contributed to the Cirus Ecosystem.
                    <br /> You will earn rewards on the remaining
                    <strong> 85%</strong>
                    <br />
                    <strong className="cirus-red">Note: </strong>
                    Increasing your stake will automatically claim all staking
                    rewards to your wallet.
                    <br />
                    <a
                      onClick={() => {
                        window.open(
                          'https://support.cirusfoundation.com/en/knowledge/cirus-token-hub#cirus-community-staking-pool'
                        );
                      }}
                    >
                      Learn More...
                    </a>
                  </span>
                )}{' '}
              </div>
            </div>
            // : null
          }
          <div className="buttons">
            {isApproving ? (
              <button className="button is-primary is-rounded is-large">
                <div className="spinner-border mr-2" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <span>Loading</span>
              </button>
            ) : !isApproving &&
              action !== 'Unstake' &&
              action !== 'Claim Reward' &&
              action !== 'Full Unstake' &&
              action !== 'Unstake and claim rewards' ? (
              <button
                className="button is-primary is-medium is-rounded"
                disabled={contractApproved ? true : false}
                onClick={preApprove}
              >
                Preapprove
              </button>
            ) : null}
            <button
              className="button is-primary is-medium is-rounded"
              disabled={
                contractApproved ||
                action == 'Unstake' ||
                action == 'Claim Reward' ||
                action == 'Full Unstake' ||
                action == 'Unstake and claim rewards'
                  ? false
                  : true
              }
              onClick={
                action === 'stake' && contractApproved ? stake : withdraw
              }
            >
              Sign Transaction
            </button>
          </div>
          {isApproving ? (
            <center className="p-2 scale-90 text-xs">
              <strong>Do not refresh or leave this page</strong>
            </center>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

SignTransactionModal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  amountToStake: PropTypes.string,
  stake: PropTypes.func,
  unstake: PropTypes.func,
  action: PropTypes.string,
  withdraw: PropTypes.func,
  preApprove: PropTypes.func,
  contractApproved: PropTypes.bool,
  isApproving: PropTypes.bool,
};

export default SignTransactionModal;
