/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { MakeMobileTableTile } from './../../../../components/tables/makeMobileTableTile';
import cirusIcon from '../../../../images/cirusToken.png';
import ethereumIcon from '../../../../images/ethicon.png';
import maticIcon from '../../../../images/maticToken.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// eslint-disable-next-line react/prop-types
export const ConvertMobileBalance = ({ balances }) => {
  // eslint-disable-next-line react/prop-types
  const { liquidEthereum, liquidPolygon, liquidUSDEthereum, liquidUSDPolygon } =
    balances;
  const ConvertMobileBalanceTile = MakeMobileTableTile(
    'Amount',
    'Value',
    'Action',
    '',
    false,
    '',
  );
  return (
    <div className="columns pt-5 pb-5 clear-last-break is-hidden-tablet m-0">
      <ConvertMobileBalanceTile
        tokenName={'Cirus ERC-20'}
        tokenIcon={cirusIcon}
        secondRowLeftContent={`${liquidEthereum} CIRUS`}
        secondRowRightContent={liquidUSDEthereum}
        thirdRowLeftContent={
          <div className="columns is-mobile">
            <div className="column pl-0 is-narrow">
              <a className="is-primary pr-5 ">Deposit</a>
            </div>
            <div className="column">
              <a className="is-primary">Withdraw</a>
            </div>
          </div>
        }
        thirdRowRightContent={''}
      />
      <ConvertMobileBalanceTile
        tokenName={'Cirus MATIC'}
        tokenIcon={cirusIcon}
        secondRowLeftContent={`${liquidPolygon} CIRUS`}
        secondRowRightContent={liquidUSDPolygon}
        thirdRowLeftContent={
          <div className="columns is-mobile">
            <div className="column pl-0 is-narrow">
              <a className="is-primary pr-5 ">Deposit</a>
            </div>
            <div className="column">
              <a className="is-primary">Withdraw</a>
            </div>
          </div>
        }
        thirdRowRightContent={''}
      />
      {/*
      <ConvertMobileBalanceTile
        tokenName={'Ethereum ERC-20'}
        tokenIcon={ethereumIcon}
        secondRowLeftContent={'5.00 ETH'}
        secondRowRightContent={'$20,000.00'}
        thirdRowLeftContent={
          <div className="columns is-mobile">
            <div className="column pl-0 is-narrow">
              <a className="is-primary pr-5 ">Deposit</a>
            </div>
            <div className="column">
              <a className="is-primary">Withdraw</a>
            </div>
          </div>
        }
        thirdRowRightContent={''}
      />
      <ConvertMobileBalanceTile
        tokenName={'Matic Token'}
        tokenIcon={maticIcon}
        secondRowLeftContent={'5.00 MATIC'}
        secondRowRightContent={'$20,000.00'}
        thirdRowLeftContent={
          <div className="columns is-mobile">
            <div className="column pl-0 is-narrow">
              <a className="is-primary pr-5 ">Deposit</a>
            </div>
            <div className="column">
              <a className="is-primary">Withdraw</a>
            </div>
          </div>
        }
        thirdRowRightContent={''}
      />*/}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    balances: state.balances,
  };
};
export default connect(mapStateToProps, (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
}))(ConvertMobileBalance);
