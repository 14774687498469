import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setBreadcrumbs } from '../../store/actions/mobileTableActions';
import ConvertBalance from './components/convertBalance';
import ConvertTransfer from './components/convertTransfer';
import ConvertBalanceHistory from './components/convertBalanceHistory/convertBalanceHistory';
import { POSClient, use, setProofApi } from '@maticnetwork/maticjs';
import { Web3ClientPlugin } from '@maticnetwork/maticjs-web3';
import { useWeb3React } from '@web3-react/core';
import './convertTokensView.scss';

const config = require('../../config');

use(Web3ClientPlugin);
setProofApi('https://apis.matic.network/');

const ConvertTokensView = (props) => {
  const { account, chainId } = useWeb3React();

  const [maticLoaded, setMaticLoaded] = useState(false);

  const maticPosClient = new POSClient();

  maticPosClient.init({
    network: 'testnet', // optional, default is testnet
    version: 'mumbai', // optional, default is mumbai
    parent: {
      provider:
        chainId == process.env.REACT_APP_NETWORK_ETHEREUM
          ? window.ethereum
          : config.root[process.env.REACT_APP_NETWORK_ETHEREUM].RPC,
      defaultConfig: {
        from: account,
      },
    },
    child: {
      provider:
        chainId == process.env.REACT_APP_NETWORK_POLYGON
          ? window.ethereum
          : config.child[process.env.REACT_APP_NETWORK_POLYGON].RPC,
      defaultConfig: {
        from: account,
      },
    },
    posRootChainManager:
      config.root[process.env.REACT_APP_NETWORK_ETHEREUM].POSRootChainManager,
    posERC20Predicate:
      config.root[process.env.REACT_APP_NETWORK_ETHEREUM].posERC20Predicate,
  });

  const withdraw = async (hash) => {
    const tokenAddress = config.tokenToAddressMap['cirus'].ethereum;
    const erc20RootToken = maticPosClient.erc20(tokenAddress, true);

    try {
      const result = await erc20RootToken.withdrawExitFaster(hash);
      const txReceipt = await result.getReceipt();
      console.log('txReceipt', txReceipt);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    props.setBreadcrumbs([{ name: 'Convert Tokens' }]);
  }, []);

  return (
    <>
      <section className="section convert-tokens">
        <div className="container main-container mt-0">
          {/* Balance/transfer row */}
          <div className="columns card-container mt-0">
            {/* Balance Card */}
            <div className="column is-half balance-card">
              <div className="card">
                <div className="card-content rpb-0 rpl-6 rpr-6 rpt-6">
                  <ConvertBalance />
                </div>
              </div>
            </div>
            {/* Transfer Card */}
            <div className="column is-half transfer-card">
              <div className="card overflow-hidden">
                <ConvertTransfer maticPosClient={maticPosClient} />
              </div>
            </div>
          </div>
          {/* Balance & Transactoin History Card */}
          <div className="columns">
            <div className="column convert-balance-history-card">
              <div className="card">
                <div className="card-content rp-0 rpt-5">
                  <ConvertBalanceHistory
                    maticPosClient={maticPosClient}
                    withdraw={withdraw}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    ...bindActionCreators({ setBreadcrumbs }, dispatch),
  }),
)(ConvertTokensView);
