import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setBreadcrumbs } from '../../../store/actions/mobileTableActions';
import MantraStakeModal from './components/mantraStakeModal';
import { MantraAllBalance } from './components/mantraAllBalance';
import { MantraStakingForm } from './components/mantraStakingForm';
import { Modal } from './../../../components/modals/modal';
import { MantraUnstakeModal } from './components/mantraUnstakeModal';
import { MantraMobileAllBalance } from './components/mantraMobileAllBalance';
import { TopTable } from '../../../components/tables/topTable';
import { MantraStakedForm } from './components/mantraStakedForm';
import { MantraWithdrawTimer } from './components/mantraModalFlow/mantraWithdrawTimer';
import { MantraConfirmingUnstake } from './components/mantraModalFlow/mantraConfirmingUnstake';
import { MantraConfirmedUnstake } from './components/mantraModalFlow/mantraConfirmedUnstake';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import StakingPool from '../../../contracts/abis/StakingPool.json';
import StakingPoolContract from '../../../contracts/abis/StakingPool.json';
import CirusToken from '../../../contracts/abis/CirusToken.json';
import { ethers } from 'ethers';
import './mantraDaoStakingView.scss';
import { setBalanceStakedPool } from './../../../store/actions/balancesActions';
const config = require('../../../config.js');

// eslint-disable-next-line react/prop-types
const MantraDaoStakingView = (props) => {
  const { balances } = props;

  // eslint-disable-next-line react/prop-types
  let { liquidEthereum, liquidPolygon, liquidUSD, rewards, stakedPool } =
    balances;
  const [stakePOOL, setStakePOOL] = useState(0);
  const [isMantraStakingModalActive, setIsMantraStakingModalActive] =
    useState(false);
  const [isMantraUnstakeModalActive, setIsMantraUnstakeModalActive] =
    useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [amountToStake, setAmountToStake] = useState(0);
  const [amountToUnstake, setAmountToUnstake] = useState(0);
  const [step, setStep] = useState('stakeNow');
  const [poolApproved, setPoolApproved] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isStaking, setIsStaking] = useState(false);
  const [action, setAction] = useState('staking');
  const [unstakedTokens, setUnstakedTokens] = useState(0);
  const [availableForWithdrawal, setAvailableForWithdrawal] = useState(0);
  const [transactionHash, setTransactionHash] = useState('');
  const toggleIsMantraUnstakeModalActive = () => {
    setIsMantraUnstakeModalActive(!isMantraUnstakeModalActive);
  };
  const toggleIsMantraStakingModalActive = (step) => {
    setStep(step);
    setIsMantraStakingModalActive(!isMantraStakingModalActive);
  };
  const { active, account, library, activate, chainId } = useWeb3React();
  const getContract = async (instance, signer, address) => {
    const contract = new ethers.Contract(address, instance.abi, signer);
    return { contract, address };
  };

  const preApprove = async () => {
    if (amountToStake <= 0) {
      return;
    }
    try {
      let CirusTokenContract = await getContract(
        CirusToken,
        library.getSigner(),
        process.env.REACT_APP_CIRUS_TOKEN_ETHEREUM,
      );
      setIsApproving(true);
      await CirusTokenContract.contract.functions
        .approve(
          process.env.REACT_APP_MANTRADAO,
          ethers.utils.parseEther(amountToStake.toString()).toString(),
        )
        .then((result) => {
          CirusTokenContract.contract.on(
            'Approval',
            (owner, spender, amount) => {
              if (account == owner) {
                setIsApproving(false);
                setPoolApproved(true);
              }
            },
          );
        });
    } catch (err) {
      console.log(err);
      setIsApproving(false);
    }
  };

  const stake = async () => {
    if (amountToStake <= 0) {
      return;
    }
    const mantraDaoContract = await getContract(
      StakingPool,
      library.getSigner(),
      process.env.REACT_APP_MANTRADAO,
    );
    try {
      setStep('confirm');
      setIsStaking(true);
      setAction('staking');
      await mantraDaoContract.contract.functions
        .stake(ethers.utils.parseEther(amountToStake.toString()).toString())
        .then((result) => {
          setTransactionHash(result.hash);
          mantraDaoContract.contract.on(
            'Staked',
            async (stakerAccount, payer, stakedAmount, mintedAmount) => {
              if (account == stakerAccount) {
                setIsStaking(false);
                const stakingPoolContractData = await getContract(
                  StakingPoolContract,
                  library.getSigner(),
                  process.env.REACT_APP_MANTRADAO,
                );
                let cirusStakingPoolStakedBalance =
                  (
                    await stakingPoolContractData.contract.functions.balanceOf(
                      account,
                    )
                  )[0] /
                  10 ** 18;

                setStakePOOL(cirusStakingPoolStakedBalance);
                setAmountToUnstake(cirusStakingPoolStakedBalance);
                setBalanceStakedPool(cirusStakingPoolStakedBalance);
                setStep('confirmedUnstake');
              }
            },
          );
        });
    } catch (err) {
      console.log(err);
      setIsStaking(false);
    }
  };

  const unStake = async () => {
    if (amountToUnstake <= 0) {
      return;
    }
    const mantraDaoContract = await getContract(
      StakingPool,
      library.getSigner(),
      process.env.REACT_APP_MANTRADAO,
    );
    try {
      setStep('confirm');
      setIsStaking(true);
      setAction('unstaking');
      await mantraDaoContract.contract.functions
        .unstake(ethers.utils.parseEther(amountToUnstake.toString()).toString())
        .then((result) => {
          setTransactionHash(result.hash);
          mantraDaoContract.contract.on(
            'Unstaked',
            async (
              stakerAccount,
              requestedAmount,
              stakedAmount,
              unstakedAmount,
              burnedAmount,
            ) => {
              if (account == stakerAccount) {
                setIsStaking(false);
                const stakingPoolContractData = await getContract(
                  StakingPoolContract,
                  library.getSigner(),
                  process.env.REACT_APP_MANTRADAO,
                );
                let cirusStakingPoolStakedBalance =
                  (
                    await stakingPoolContractData.contract.functions.balanceOf(
                      account,
                    )
                  )[0] /
                  10 ** 18;
                setStakePOOL(cirusStakingPoolStakedBalance);
                setAmountToUnstake(cirusStakingPoolStakedBalance);
                setBalanceStakedPool(cirusStakingPoolStakedBalance);
                setStep('confirmedUnstake');
              }
            },
          );
        });
    } catch (err) {
      console.log(err);
      setIsStaking(false);
    }
  };

  const withdraw = async () => {
    if (availableForWithdrawal <= 0) {
      return;
    }
    const mantraDaoContract = await getContract(
      StakingPool,
      library.getSigner(),
      process.env.REACT_APP_MANTRADAO,
    );
    try {
      await mantraDaoContract.contract.functions.withdraw().then((result) => {
        console.log(result);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const init = async () => {
    if (library) {
      let CirusTokenContract = await getContract(
        CirusToken,
        library.getSigner(),
        process.env.REACT_APP_CIRUS_TOKEN_ETHEREUM,
      );

      let mantraDaoContract = await getContract(
        StakingPool,
        library.getSigner(),
        process.env.REACT_APP_MANTRADAO,
      );

      //check if the mantradao contract has been approved to spend
      try {
        await CirusTokenContract.contract.functions
          .allowance(account, process.env.REACT_APP_MANTRADAO)
          .then((result) => {
            let allowance = ethers.utils.formatEther(result[0].toString());
            if (allowance > 0) setPoolApproved(true);
          });
      } catch (err) {
        console.log(err);
      }
      setAmountToUnstake(parseInt(stakedPool * 10000) / 10000);
      setCurrentBalance(
        chainId == process.env.REACT_APP_NETWORK_ETHEREUM
          ? parseFloat(liquidEthereum)
          : parseFloat(liquidPolygon),
      );
      setStakePOOL(stakedPool);

      //get tokens available for withdrawal
      try {
        await mantraDaoContract.contract.functions
          .getUnstake(account)
          .then((data) => {
            let today = Math.floor(new Date().getTime() / 1000);
            let availableDate = parseInt(data.result.applicableAt._hex, 16);
            let unstaked = parseInt(data.result.amount._hex, 16) / 10 ** 18;
            setUnstakedTokens(unstaked);
            if (today > availableDate) setAvailableForWithdrawal(unstaked);
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    init();
  }, [library, liquidEthereum]);

  useEffect(() => {
    props.setBreadcrumbs([
      { name: 'Staking', link: '/staking' },
      { name: 'MantraDAO Staking' },
    ]);
  }, []);

  return (
    <>
      <section className="section">
        <div className="container main-container mt-0">
          <TopTable />
          <div className="columns card-container">
            <div className="column">
              <div className="card has-small-padding has-no-horizontal-padding">
                <div className="card-content">
                  <div className="tab-area">
                    {/* <div className="standard-table tabs">
                      <ul>
                        <li className="is-active">
                          <a href="/staking/mantra-dao-staking">All</a>
                        </li>
                        <li>
                          <a href="/staking/mantra-dao-staking">Stake</a>
                        </li>
                        <li>
                          <a href="/staking/mantra-dao-staking">Nominate</a>
                        </li>
                      </ul>
                    </div>*/}
                    <section className="tab-content">
                      {/* balance table */}
                      <MantraAllBalance stakedPool={stakePOOL} />
                      {/* mobile balance & staking */}
                      <MantraMobileAllBalance
                        toggleIsMantraStakingModalActive={
                          toggleIsMantraStakingModalActive
                        }
                        stakedPool={stakePOOL}
                      />
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-5 is-hidden-touch">
              <div className="card has-no-horizontal-padding has-small-padding">
                <div className="card-content">
                  {stakePOOL > 0 ? (
                    <MantraStakedForm
                      cirusBalance={currentBalance}
                      setAmountToStake={setAmountToStake}
                      amountToStake={amountToStake}
                      stakedAmount={parseFloat(stakePOOL)}
                      isMantraUnstakeModalActive={isMantraUnstakeModalActive}
                      toggleIsMantraUnstakeModalActive={
                        toggleIsMantraUnstakeModalActive
                      }
                      toggleIsMantraStakingModalActive={
                        toggleIsMantraStakingModalActive
                      }
                      isMantraStakingModalActive={isMantraStakingModalActive}
                      availableForWithdrawal={availableForWithdrawal}
                      unstakedTokens={unstakedTokens}
                      withdraw={withdraw}
                    />
                  ) : (
                    <MantraStakingForm
                      cirusBalance={currentBalance}
                      isMantraStakingModalActive={isMantraStakingModalActive}
                      toggleIsMantraStakingModalActive={
                        toggleIsMantraStakingModalActive
                      }
                      setAmountToStake={setAmountToStake}
                      stakedAmount={parseFloat(stakePOOL)}
                      amountToStake={amountToStake}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        isActive={isMantraStakingModalActive}
        onClose={() => setIsMantraStakingModalActive(false)}
      >
        <MantraStakeModal
          stakedAmount={parseFloat(stakePOOL)}
          amount={amountToStake}
          step={step}
          setStep={setStep}
          stake={stake}
          preApprove={preApprove}
          poolApproved={poolApproved}
          isApproving={isApproving}
          isStaking={isStaking}
          action={action}
          cirusBalance={currentBalance}
          setAmountToStake={setAmountToStake}
          tx={transactionHash}
        />
      </Modal>
      <Modal
        isActive={isMantraUnstakeModalActive}
        onClose={() => setIsMantraUnstakeModalActive(false)}
      >
        {step == 'unstaking' ? (
          <MantraWithdrawTimer unStake={unStake} />
        ) : step == 'confirm' ? (
          <MantraConfirmingUnstake
            action={action}
            amount={parseFloat(amountToUnstake)}
          />
        ) : step == 'confirmedUnstake' ? (
          <MantraConfirmedUnstake
            action={action}
            tx={transactionHash}
            amount={parseFloat(amountToUnstake)}
          />
        ) : (
          <MantraUnstakeModal
            amount={parseFloat(amountToUnstake)}
            setAmountToUnstake={setAmountToUnstake}
            setStep={setStep}
          />
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    balances: state.balances,
  };
};

export default connect(mapStateToProps, (dispatch) => ({
  ...bindActionCreators({ setBreadcrumbs, setBalanceStakedPool }, dispatch),
}))(MantraDaoStakingView);
