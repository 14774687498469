/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import './notificationsModal.scss';

const NotificationsModal = ({ notifications }) => {
  const [isPastActive, setPastActive] = useState(false);
  return (
    <>
      <div className="notifications">
        <p className="title has-text-left">Notifications</p>
      </div>
      <div className="standard-table tabs notifs">
        <ul>
          <li
            onClick={() => setPastActive(false)}
            className={isPastActive ? '' : 'is-active'}
          >
            <a>Recent</a>
          </li>
          <li
            onClick={() => setPastActive(true)}
            className={isPastActive ? 'is-active' : ''}
          >
            <a>Past</a>
          </li>
        </ul>
      </div>
      <div className="tab-content notifications">
        {!isPastActive && (
          <>
            {notifications.map((notification, index) => (
              <div
                className="columns is-mobile m-0"
                key={`notification-recent-${index}`}
              >
                <div className="column p-0">
                  <p>{notification.content}</p>
                </div>
                <div className="column p-0 has-text-right is-narrow dull">
                  <p>{notification.date}</p>
                </div>
              </div>
            ))}
          </>
        )}
        {isPastActive && (
          <>
            {notifications.map((notification, index) => (
              <div
                className="columns is-mobile m-0"
                key={`notification-recent-${index}`}
              >
                <div className="column">
                  <p>{notification.content}</p>
                </div>
                <div className="column has-text-right is-narrow dull">
                  <p>{notification.date}</p>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};
NotificationsModal.propTypes = {
  notifications: PropTypes.array,
};
const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.notifications,
  };
};
export default connect(mapStateToProps)(NotificationsModal);
