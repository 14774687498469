import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './designElementsView.scss';
import AmountBox from '../../components/amountBox';
import InlineNotification from '../../components/inlineNotification/inlineNotification';
import ExampleModal from '../../components/exampleModal/exampleModal';
import StateIndicator from '../../components/stateIndicator/stateIndicator';
import TopNavbar from '../../layout/_components/topNavbar';

const DesignElementsView = () => {
  const [isExampleModalActive, setIsExampleModalActive] = useState(false);

  return (
    <>
      <TopNavbar isPrimary={true} />
      <section className="section">
        <div className="container main-container">
          {/* Section 1 Assets */}
          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <h2 className="subtitle card-title is-3">Card</h2>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card is-highlighted-card">
                <div className="card-content">
                  <h2 className="subtitle card-title is-3">Card Highlighted</h2>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <button className="button is-primary is-outlined is-rounded is-fullwidth">
                    Stake
                  </button>
                  <br />
                  <br />
                  <button
                    className="button is-primary is-outlined is-rounded is-fullwidth"
                    disabled
                  >
                    Stake
                  </button>
                  <br />
                  <br />
                  <button
                    className="button is-primary is-rounded is-fullwidth"
                    disabled
                  >
                    Stake
                  </button>
                  <br />
                  <br />
                  <button className="button is-primary is-rounded is-fullwidth">
                    Stake
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Section 2 Assets */}
          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <input type="text" className="input" placeholder="Hello" />
                  <br />
                  <br />
                  <input
                    type="text"
                    className="input is-rounded"
                    placeholder="Hello"
                  />
                  <br />
                  <br />
                  <div className="field has-addons fixed-right-addon">
                    <p className="control">
                      <span className="button is-static is-medium is-rounded">
                        <img
                          src="cirusToken.svg"
                          alt="Cirus Token"
                          width="80"
                        />
                      </span>
                    </p>
                    <p className="control is-expanded">
                      <input
                        type="text"
                        className="input is-medium is-rounded"
                        placeholder="Hello"
                      />
                    </p>
                  </div>
                  <br />
                  <div className="control has-icons-right">
                    <input
                      className="input is-medium is-rounded"
                      defaultValue="0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266"
                    />
                    <span
                      className="icon action-addon is-small is-right has-text-primary"
                      onClick={() => alert('Copied')}
                      title="Copy to Clipboard"
                    >
                      <i className="far fa-clone" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <div className="select is-fullwidth">
                    <select>
                      <option>Select dropdown</option>
                      <option>Option A</option>
                      <option>Option B</option>
                      <option>Option C</option>
                    </select>
                  </div>
                  <br />
                  <br />
                  <div className="dropdown is-rounded is-active fixed-200">
                    <div className="dropdown-trigger">
                      <button
                        className="button is-medium is-justify-content-left is-rounded"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                      >
                        <span>Select Action</span>
                        <span className="icon is-small">
                          <i className="fas fa-angle-down" aria-hidden="true" />
                        </span>
                      </button>
                    </div>
                    <div className="dropdown-menu is-rounded" role="menu">
                      <div className="dropdown-content">
                        <a
                          href="https://cirus-dashboard.netlify.app/"
                          className="dropdown-item subtitle is-5"
                        >
                          Stake
                        </a>
                        <hr className="dropdown-divider" />
                        <a
                          href="https://cirus-dashboard.netlify.app/"
                          className="dropdown-item subtitle is-5"
                        >
                          Withdraw
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <AmountBox
                    title="Liquid Balance (No Avatar)"
                    amount="9,000 CIRUS"
                    convertedAmount="$18,025.25"
                  />
                  <br />
                  <br />
                  <AmountBox
                    title="Liquid Balance (With Avatar)"
                    amount="9,000 CIRUS"
                    convertedAmount="$18,025.25"
                    avatar="cirusToken.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Section 3 Assets */}
          <div className="columns">
            <div className="column">
              <div className="card is-highlighted-card has-token-background">
                <div className="card-content">
                  <h2 className="subtitle card-title is-3">Card</h2>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <table className="table is-fullwidth is-spacious">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Action</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="icon-text">
                            <span className="icon mr-2">
                              <img src="cirusToken.svg" alt="Cirus" />
                            </span>
                            <span>ERC-20</span>
                          </span>
                        </td>
                        <td>Deposit</td>
                        <td>5.00 CIRUS</td>
                      </tr>
                      <tr className="is-selected">
                        <td>
                          <span className="icon-text">
                            <span className="icon mr-2">
                              <img src="cirusToken.svg" alt="Cirus" />
                            </span>
                            <span>Cirus</span>
                          </span>
                        </td>
                        <td>Deposit</td>
                        <td>5.00 CIRUS</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="icon-text">
                            <span className="icon mr-2">
                              <img src="cirusToken.svg" alt="Cirus" />
                            </span>
                            <span>MATIC</span>
                          </span>
                        </td>
                        <td>Deposit</td>
                        <td>5.00 CIRUS</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <div className="tabs">
                    <ul>
                      <li>
                        <a href="https://cirus-dashboard.netlify.app/">
                          Tokens
                        </a>
                      </li>
                      <li className="is-active">
                        <a href="https://cirus-dashboard.netlify.app/">
                          Transactions
                        </a>
                      </li>
                      <li>
                        <a href="https://cirus-dashboard.netlify.app/">
                          History
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <section className="tab-content">Transactions List</section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Section 4 Assets */}
          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <div className="columns">
                    <div className="column is-3">
                      <figure className="image is-96x96">
                        <img
                          src="notificationModalHeaderIcon.svg"
                          alt="Notification"
                        />
                      </figure>
                    </div>
                    <div className="column mt-5">
                      <p className="title is-4">Notification</p>
                    </div>
                  </div>

                  <div className="notification is-tight">
                    You’ve logged in for <strong>3</strong> consecutive days,
                    <strong>5</strong> more days and you’ll increase your
                    multiplier.
                  </div>
                  <div className="notification is-tight">
                    Your reward account grew by <strong>%3</strong> or&nbsp;
                    <strong>$40</strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <button
                    className="button is-primary is-outlined"
                    onClick={() => setIsExampleModalActive(true)}
                  >
                    Open Example Modal
                  </button>
                  <ExampleModal
                    isActive={isExampleModalActive}
                    onClose={() => setIsExampleModalActive(false)}
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <a href="https://cirus-dashboard.netlify.app/">
                          <img
                            className="mr-2"
                            src="breadcrumbIcon.svg"
                            alt="Breadcrumb"
                          />
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a href="https://cirus-dashboard.netlify.app/">Stake</a>
                      </li>
                      <li className="is-active">
                        <a href="https://cirus-dashboard.netlify.app/">
                          Cirus Community Staking
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          {/* Section 5 Assets */}
          <div className="columns">
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <StateIndicator state="is-active">Active</StateIndicator>
                  <br />
                  <StateIndicator state="is-pending">Pending</StateIndicator>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-content">
                  <ul className="steps has-content-centered">
                    <li className="steps-segment">
                      <span className="steps-marker">
                        <i className="fa fa-check"></i>
                      </span>
                      <div className="steps-content">
                        <p>Approve</p>
                      </div>
                    </li>
                    <li className="steps-segment">
                      <span className="steps-marker">
                        <i className="fa fa-check"></i>
                      </span>
                      <div className="steps-content">
                        <p>Confirmed</p>
                      </div>
                    </li>
                    <li className="steps-segment is-active">
                      <span className="steps-marker is-hollow"></span>
                      <div className="steps-content">
                        <p>Completed</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="card-content has-text-centered">
                  <p className="title is-4">Loading Spinner</p>
                  <i className="fas fa-circle-notch fa-5x fa-spin has-text-primary" />
                </div>
              </div>
            </div>
          </div>
          {/* Section 6 Assets */}

          <div className="columns">
            <div className="column is-6">
              <div className="card">
                <div className="card-content">
                  <InlineNotification>
                    <small>
                      You have logged in for <strong>3</strong> days.
                    </small>
                  </InlineNotification>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

DesignElementsView.propTypes = {};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    ...bindActionCreators({}, dispatch),
  }),
)(DesignElementsView);
