import React from 'react';
import { PropTypes } from 'prop-types';

export const MantraStakeAmount = ({
  cirusToken,
  amount,
  setStep,
  stakedAmount,
  cirusBalance,
  setAmountToStake,
}) => {
  const setAmount = (percentage) => {
    setAmountToStake(
      parseInt((percentage / 100) * cirusBalance * 10000) / 10000,
    );
  };
  return (
    <div className="has-text-centered mb-6 mr-6 ml-6">
      <div className="notification pt-5 mb-6">
        <div className="columns ml-6 mr-6 pt-4">
          <div className="column has-text-weight-bold has-text-left">
            Current Total Stake:
          </div>
          <div className="column has-text-weight-bold has-text-right pb-5">
            {stakedAmount} Cirus
          </div>
        </div>
      </div>
      <div className="field has-addons fixed-right-addon">
        <p className="control">
          <span className="button is-static is-large is-rounded">
            <img src={cirusToken} alt="Cirus Token" width="80" />
          </span>
        </p>
        <p className="control is-expanded">
          <input
            type="text"
            className="input is-large is-rounded"
            placeholder="Stake amount"
            value={amount}
            onChange={(e) => setAmountToStake(e.target.value)}
          />
        </p>
      </div>

      <div className="buttons is-justify-content-center has-text-centered mt-5 mb-6">
        <button
          className="button is-primary is-outlined is-wide-button is-rounded has-text-centered"
          onClick={(e) => setAmount(25)}
        >
          25%
        </button>
        <button
          className="button is-primary is-outlined is-wide-button is-rounded has-text-centered"
          onClick={(e) => setAmount(50)}
        >
          50%
        </button>
        <button
          className="button is-primary is-outlined is-wide-button is-rounded has-text-centered"
          onClick={(e) => setAmount(75)}
        >
          75%
        </button>
        <button
          className="button is-primary is-outlined is-wide-button is-rounded has-text-centered"
          onClick={(e) => setAmount(100)}
        >
          Full
        </button>
      </div>
      <p>
        <button
          className="button is-primary is-rounded is-large"
          onClick={(e) => setStep('stakeNow')}
        >
          Next
        </button>
      </p>
    </div>
  );
};
MantraStakeAmount.propTypes = {
  cirusToken: PropTypes.string,
  amount: PropTypes.number,
  setStep: PropTypes.func,
  setAmountToStake: PropTypes.func,
  stakedAmount: PropTypes.number,
  cirusBalance: PropTypes.number,
};
