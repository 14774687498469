import { ACTION_TYPES } from '../actionTypes';
import { combineReducers } from 'redux';
import { initialMobileTable } from './../initialState/mobileTable.js';

const isExpanded = (state = initialMobileTable().isExpanded, action) => {
  if (action.type === ACTION_TYPES.MOBILE_TABLE_EXPAND_SET) {
    return action.payload;
  }
  return state;
};
const breadcrumbs = (state = initialMobileTable().breadcrumbs, action) => {
  if (action.type === ACTION_TYPES.SET_BREADCRUMBS) {
    return action.payload;
  }
  return state;
};

export default combineReducers({
  isExpanded,
  breadcrumbs,
});
