import React from 'react';
import { PropTypes } from 'prop-types';
import AmountBox from '../../../../../components/amountBox';
import ethIcon from '../../../../../images/ethicon.png';
import './mantraWithdrawTimer.scss';

export const MantraWithdrawTimer = ({ unStake }) => {
  return (
    <>
      <div className="columns is-centered mt-6 mb-6">
        <div className="column is-4 ml-6">
          <AmountBox middleTxt="Cirus / ETH" avatar={ethIcon} />
        </div>
      </div>
      <div className="has-text-centered mb-6 mr-6 ml-6">
        <p className="title is-2 mb-6">Withdrawal Timer Reset</p>
        <p className="important-txt mt-5">
          <span className="has-text-primary has-text-weight-bold">
            Important&nbsp;
          </span>
          If you are currently unstaking and decide to unstake more tokens half
          way through, the timer will be reset to 8-days, and you will be able
          to withdraw both set of tokens after 8~days.
        </p>

        {/*<p className="mb-5 mt-6">
        <button className="button is-primary is-rounded is-large">
          Continue
        </button>
      </p>*/}
        <p>
          <button
            className="button is-primary is-rounded is-large width-unset px-5 mt-6"
            onClick={(e) => unStake()}
          >
            Unstake Immediately
          </button>
        </p>
      </div>
    </>
  );
};
MantraWithdrawTimer.propTypes = {
  cirusToken: PropTypes.string,
  unStake: PropTypes.func,
};
