/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import './convertBalance.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useWeb3React } from '@web3-react/core';
import { getNetworkName } from '../../../utils/helpers';

// eslint-disable-next-line react/prop-types
const ConvertBalance = ({ balances }) => {
  const { active, account, library, activate, chainId } = useWeb3React();
  // eslint-disable-next-line react/prop-types
  const { liquid, liquidUSD } = balances;
  const [networkName, setNetworkName] = useState();

  useEffect(() => {
    setNetworkName(getNetworkName(chainId));
  }, [chainId]);
  return (
    <>
      <div className="columns">
        <div className="field has-addons column is is-two-third">
          <p className="control has-icon--left">
            <span className="button is-rounded is-static has-background-white mb-5">
              <span className="icon is-small is-left">
                <img
                  alt="Icon: Wireless Connection Active"
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
                />
              </span>
              <small className="mainnet-notifcation">
                Connected to {networkName}
              </small>
            </span>
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p className="subtitle is-spaced mb-1">Total Wallet Balance</p>
          <h3 className="title mb-1">
            ${new Intl.NumberFormat().format(liquidUSD)} USD
          </h3>
          <small className="dull">{liquid} Cirus Tokens</small>
        </div>
      </div>
      <div className="columns column FAQ-box-sibling">
        <p className="rpb-5 balance-text">
          Bridge your CIRUS Tokens from the Ethereum network to the Polygon
          Network, or vice versa.
        </p>
      </div>
      {/* href linter disabled at top */}
      <div className="FAQ-box">
        <a
          className="balance columns is-mobile pt-4 pb-4"
          href="https://www.youtube.com/playlist?list=PLslsfan1R_z0Epvnqsj29V1LBAh99dzu9"
          target="_blank"
          rel="noreferrer"
        >
          <div className="column pl-0">
            <a className="is-primary">How It Works?</a>
          </div>
          <div className="column">
            <i className="fas fa-chevron-right is-primary is-pulled-right has-text-black"></i>
          </div>
        </a>
        <hr className="balance break" />
        <a
          className="balance columns is-mobile pt-4 pb-4"
          href="https://wiki.polygon.technology/docs/faq/wallet-bridge-faq/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="column pl-0">
            <a className="is-primary">FAQ</a>
          </div>
          <div className="column">
            <i className="fas fa-chevron-right is-pulled-right has-text-black"></i>
          </div>
        </a>
        <hr className="balance break" />
        <a
          className="balance columns is-mobile pt-4 pb-4"
          href="https://wiki.polygon.technology/docs/develop/wallets/polygon-web-wallet/web-wallet-v2-guide/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="column pl-0">
            <a className="is-primary">User guide</a>
          </div>
          <div className="column">
            <i className="fas fa-chevron-right is-pulled-right has-text-black"></i>
          </div>
        </a>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    balances: state.balances,
  };
};
export default connect(mapStateToProps, (dispatch) => ({
  dispatch,
  ...bindActionCreators({}, dispatch),
}))(ConvertBalance);
