export const ACTION_TYPES = Object.freeze({
  BALANCE_SET_TOTAL: 'BALANCE_SET_TOTAL',
  BALANCE_SET_LIQUID_ETHEREUM: 'BALANCE_SET_LIQUID_ETHEREUM',
  BALANCE_SET_LIQUID_POLYGON: 'BALANCE_SET_LIQUID_POLYGON',
  BALANCE_SET_LIQUID: 'BALANCE_SET_LIQUID',
  BALANCE_SET_TOTAL_STAKED: 'BALANCE_SET_TOTAL_STAKED',
  BALANCE_SET_STAKED_CONTRACT: 'BALANCE_SET_STAKED_CONTRACT',
  BALANCE_SET_STAKED_POOL: 'BALANCE_SET_STAKED_POOL',
  BALANCE_SET_REWARDS: 'BALANCE_SET_REWARDS',
  BALANCE_SET_STAKED_CONTRACT_USD: 'BALANCE_SET_STAKED_CONTRACT_USD',
  BALANCE_SET_STAKED_POOL_USD: 'BALANCE_SET_STAKED_POOL_USD',
  BALANCE_SET_TOTAL_USD: 'BALANCE_SET_TOTAL_USD',
  BALANCE_SET_LIQUID_USD: 'BALANCE_SET_LIQUID_USD',
  BALANCE_SET_LIQUID_USD_ETHEREUM: 'BALANCE_SET_LIQUID_USD_ETHEREUM',
  BALANCE_SET_LIQUID_USD_POLYGON: 'BALANCE_SET_LIQUID_USD_POLYGON',
  BALANCE_SET_TOTAL_STAKED_USD: 'BALANCE_SET_TOTAL_STAKED_USD',
  BALANCE_SET_REWARDS_USD: 'BALANCE_SET_REWARDS_USD',
  BALANCE_SET_APY: 'BALANCE_SET_APY',
  CURRENCY_SET_CIRUS_USD: 'CURRENCY_SET_CIRUS_USD',
  NOTIFICATIONS_SET: 'NOTIFICATIONS_SET',
  MOBILE_TABLE_EXPAND_SET: 'MOBILE_TABLE_EXPAND_SET',
  STAKING_CONTRACT_TOTAL_BALANCE: 'STAKING_CONTRACT_TOTAL_BALANCE',
  STAKING_CONTRACT_TOTAL_BALANCE_USD: 'STAKING_CONTRACT_TOTAL_BALANCE_USD',
  SET_BREADCRUMBS: 'SET_BREADCRUMBS',
});
