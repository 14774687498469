import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ConditionalRoute } from './components/conditionalRoute';
import { AppRoutesConfig } from './routeConfig';

const Router = () => {
  function getRouteComponents(route, path) {
    const parentPath = path ? path + route.path : route.path;
    const RouteComponent = route.component;
    const routeComponent = (
      <ConditionalRoute
        path={parentPath}
        exact={true}
        redirectTo={route.redirectTo}
        key={parentPath}
      >
        {RouteComponent && <RouteComponent />}
      </ConditionalRoute>
    );
    const subroutes = route.subroutes || [];
    const subrouteComponents = subroutes
      .map((subroute) => getRouteComponents(subroute, parentPath))
      .flat(100);

    return [routeComponent].concat(subrouteComponents);
  }

  function parseRoutes(routeConfig) {
    const Layout = routeConfig.layoutComponent;
    const routeComponents = routeConfig.routes
      .map((route) => getRouteComponents(route))
      .flat(100);

    return (
      <>
        {Layout ? (
          <Layout>
            {routeComponents.map((routeComponent) => routeComponent)}
          </Layout>
        ) : (
          routeComponents.map((routeComponent) => routeComponent)
        )}
      </>
    );
  }

  return (
    <BrowserRouter>
      <Switch>{parseRoutes(AppRoutesConfig)}</Switch>
    </BrowserRouter>
  );
};

export default Router;
