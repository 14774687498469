import { ACTION_TYPES } from '../actionTypes';

export const setBalanceTotal = (total) => ({
  type: ACTION_TYPES.BALANCE_SET_TOTAL,
  payload: total,
});
export const setBalanceTotalStaked = (staked) => ({
  type: ACTION_TYPES.BALANCE_SET_TOTAL_STAKED,
  payload: staked,
});
export const setBalanceStakedContract = (staked) => ({
  type: ACTION_TYPES.BALANCE_SET_STAKED_CONTRACT,
  payload: staked,
});
export const setBalanceStakedPool = (staked) => ({
  type: ACTION_TYPES.BALANCE_SET_STAKED_POOL,
  payload: staked,
});
export const setBalanceLiquidEthereum = (liquid) => ({
  type: ACTION_TYPES.BALANCE_SET_LIQUID_ETHEREUM,
  payload: liquid,
});
export const setBalanceLiquidPolygon = (liquid) => ({
  type: ACTION_TYPES.BALANCE_SET_LIQUID_POLYGON,
  payload: liquid,
});
export const setBalanceLiquid = (liquid) => ({
  type: ACTION_TYPES.BALANCE_SET_LIQUID,
  payload: liquid,
});
export const setBalanceReward = (reward) => ({
  type: ACTION_TYPES.BALANCE_SET_REWARDS,
  payload: reward,
});
export const setBalanceApy = (apy) => ({
  type: ACTION_TYPES.BALANCE_SET_APY,
  payload: apy,
});
export const setBalanceStakedContractUSD = (stakedContractUSD) => ({
  type: ACTION_TYPES.BALANCE_SET_STAKED_CONTRACT_USD,
  payload: stakedContractUSD,
});
export const setBalanceStakedPoolUSD = (stakedPoolUSD) => ({
  type: ACTION_TYPES.BALANCE_SET_STAKED_POOL_USD,
  payload: stakedPoolUSD,
});
export const setBalanceTotalUSD = (totalUSD) => ({
  type: ACTION_TYPES.BALANCE_SET_TOTAL_USD,
  payload: totalUSD,
});
export const setBalanceTotalStakedUSD = (stakedUSD) => ({
  type: ACTION_TYPES.BALANCE_SET_TOTAL_STAKED_USD,
  payload: stakedUSD,
});
export const setBalanceLiquidUSD = (liquidUSD) => ({
  type: ACTION_TYPES.BALANCE_SET_LIQUID_USD,
  payload: liquidUSD,
});
export const setBalanceLiquidUSDEthereum = (liquidUSDEthereum) => ({
  type: ACTION_TYPES.BALANCE_SET_LIQUID_USD_ETHEREUM,
  payload: liquidUSDEthereum,
});
export const setBalanceLiquidUSDPolygon = (liquidUSDPolygon) => ({
  type: ACTION_TYPES.BALANCE_SET_LIQUID_USD_POLYGON,
  payload: liquidUSDPolygon,
});
export const setBalanceRewardUSD = (rewardUSD) => ({
  type: ACTION_TYPES.BALANCE_SET_REWARDS_USD,
  payload: rewardUSD,
});
export const setStakingContractTotalBalance = (
  stakingContractTotalBalance,
) => ({
  type: ACTION_TYPES.STAKING_CONTRACT_TOTAL_BALANCE,
  payload: stakingContractTotalBalance,
});
export const setStakingContractTotalBalanceUSD = (
  stakingContractTotalBalanceUSD,
) => ({
  type: ACTION_TYPES.STAKING_CONTRACT_TOTAL_BALANCE_USD,
  payload: stakingContractTotalBalanceUSD,
});
