import React from 'react';
import { PropTypes } from 'prop-types';
import { cnb } from 'cnbuilder';

export const ConvertProgressBar = ({
  isApproved,
  isConfirmed,
  isCompleted,
}) => {
  return (
    <div className="column">
      <div className="card-content p-0">
        <ul className="steps has-content-centered">
          <li
            className={cnb('steps-segment', {
              'is-active': !isApproved,
            })}
          >
            <span className={cnb('steps-marker', { 'is-hollow': !isApproved })}>
              {isApproved && <i className="fa fa-check"></i>}
            </span>
            <div className="steps-content">
              <p>Approve</p>
            </div>
          </li>
          <li
            className={cnb('steps-segment', {
              'is-active': isApproved && !isConfirmed,
            })}
          >
            <span
              className={cnb('steps-marker', { 'is-hollow': !isConfirmed })}
            >
              {isConfirmed && <i className="fa fa-check"></i>}
            </span>
            <div className="steps-content">
              <p>Confirmed</p>
            </div>
          </li>
          <li
            className={cnb('steps-segment', {
              'is-active': isConfirmed && !isCompleted,
            })}
          >
            <span
              className={cnb('steps-marker', { 'is-hollow': !isCompleted })}
            >
              {isCompleted && <i className="fa fa-check"></i>}
            </span>
            <div className="steps-content">
              <p>Completed</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

ConvertProgressBar.propTypes = {
  isApproved: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  isCompleted: PropTypes.bool,
};
