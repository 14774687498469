import React from 'react';
import { PropTypes } from 'prop-types';

export const ConvertMoveFundsConfirm = ({
  tokenName = 'Cirus MATIC',
  tokenNameShort = 'CIRUS',
  amount = '0.01',
  onTransfer2,
}) => {
  const tokenIcon =
    'https://assets.coingecko.com/coins/images/17798/small/8p0Bvi90_400x400.jpg?1629281912';
  return (
    <div>
      <div className="columns column is-centered mb-0">
        <p className="transfer title">Confirm Transfer</p>
      </div>
      <div className="columns column is-centered pt-0">
        <p className="transfer content">Please review your transactions</p>
      </div>
      <div className="columns column is-centered">
        <img alt={`${tokenName} icon`} src={tokenIcon} width="18" height="18" />
      </div>
      <div className="columns">
        <div className="column has-text-centered">
          <p className="transfer sub">{amount}</p>
          <p className="transfer sub">{tokenNameShort}</p>
        </div>
      </div>
      <div className="columns is-vcentered">
        <div className="column has-background-light pl-0 ml-3 has-text-centered">
          <p className="transfer sub">Polygon Network</p>
        </div>
        <div className="column is-narrow">
          <i className="fas fa-long-arrow-alt-right"></i>
          {/* <span>--&gt;</span> */}
        </div>
        <div className="column has-background-light pr-0 mr-3 has-text-centered">
          <p className="transfer sub">Ethereum Network</p>
        </div>
      </div>
      <hr />
      <p className="transfer subtitle">Transfer Mode: PoS Bridge</p>
      <p className="transfer content">
        PoS security is provided by the PoS validators. It will take
        approximately 3 hours when you have to transfer fund back to Ethereum.
      </p>
      <hr />
      <div className="columns column is-centered mb-3">
        <p className="transfer note">Estimated Transaction fee -$16.93</p>
      </div>
      <div className="columns column is-centered">
        <button
          onClick={onTransfer2}
          className="transfer content button is-primary is-rounded mt-6 pt-2 pr-6 pb-2 pl-6"
        >
          Continue
        </button>
      </div>
    </div>
  );
};
ConvertMoveFundsConfirm.propTypes = {
  tokenName: PropTypes.string,
  tokenNameShort: PropTypes.string,
  amount: PropTypes.any,
  onTransfer2: PropTypes.any,
};
