import React from 'react';
import MobileTopTable from './mobileTopTable';
import DesktopTopTable from './desktopTopTable';

export const TopTable = () => {
  return (
    <div className="columns topTableElement">
      <div className="column mt-1 mb-2">
        <div className="card has-small-padding is-clipped">
          <div className="">
            <MobileTopTable />
          </div>
          {/* <div className="is-hidden-mobile">
            <DesktopTopTable />
          </div> */}
        </div>
      </div>
    </div>
  );
};
