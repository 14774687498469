import React, { useEffect } from 'react';
import PropTypes, { func } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setBreadcrumbs } from '../../store/actions/mobileTableActions';
import AmountBox from '../../components/amountBox';
import StateIndicator from '../../components/stateIndicator/stateIndicator';
import StakingContract from '../../contracts/abis/NewStakingContract.json';
import { TopTable } from './../../components/tables/topTable';
import './stakingView.scss';
import RedirectModal from '../../components/redirectModal/redirectModal';
import { ethers, BigNumber } from 'ethers';

const StakingView = (props) => {
  const { balances } = props;

  const { stakedContract, stakedContractUSD, stakedPool, rewards, rewardsUSD } =
    balances;

  const [isRedirectingActive, setIsRedirectingActive] = React.useState(false);
  const [apy, setApy] = React.useState(0);
  const [paused, setPaused] = React.useState(false);

  const web3 = async () => {
    // @ts-ignore
    const provider = new ethers.providers.JsonRpcProvider(
      'https://rpc.ankr.com/eth',
    );
    const contractInstance = new ethers.Contract(
      '0x64C796eef81374442AB0e6B6Ad4C2Ceb14EFcbF0',
      StakingContract.abi,
      provider,
    );

    const status = await contractInstance.functions.paused();
    setPaused(status);

    const rewards = await contractInstance.functions.estimatedAPY();
    setApy(rewards / 100);
  };

  useEffect(() => {
    web3();
  }, []);
  useEffect(() => {
    props.setBreadcrumbs([{ name: 'Staking' }]);
  }, []);

  return (
    <>
      <section className="section">
        <div className="container main-container mt-0">
          <TopTable />
          <div className="columns card-container is-multiline">
            <div className="column is-half">
              <div className="dashboard card cirus-staking-card is-hoverable has-large-vertical-padding">
                <div className="card-content">
                  <div className="dashboard">
                    {paused ? (
                      <StateIndicator
                        state="is-active"
                        classNames={'small has-text-weight-semibold'}
                      >
                        Active
                      </StateIndicator>
                    ) : (
                      <StateIndicator
                        state="is-pending"
                        classNames={'small has-text-weight-semibold'}
                      >
                        Not Active
                      </StateIndicator>
                    )}
                    <p className="title is-2 mt-5">Cirus Community Staking</p>
                    <div className="columns m-0 is-mobile cirus-staking last-element">
                      <div className="column is-narrow ">
                        <AmountBox
                          topTxt="Staked amount"
                          middleTxt={`${stakedContract} CIRUS`}
                          bottomTxt={`$${stakedContractUSD} USD`}
                          className="is-small"
                        />
                      </div>
                      <div className="column is-narrow">
                        <AmountBox
                          topTxt="Earned"
                          middleTxt={`${rewards} CIRUS`}
                          bottomTxt={`$${rewardsUSD} USD`}
                          className="is-small"
                        />
                      </div>
                      <div className="column is-3 is-narrow">
                        <AmountBox
                          topTxt="APY"
                          middleTxt={`${apy} %`}
                          bottomTxt=""
                          className="is-small"
                        />
                      </div>
                    </div>

                    <a
                      className="button is-primary is-outlined is-large is-rounded"
                      href="/staking/cirus-staking"
                    >
                      {stakedContract > 0 ? 'View Stake' : 'Stake'}
                    </a>
                    <div className="audit-image">
                      <img src={'Hacken.svg'} alt={''} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="dashboard card mantra-dao-card is-hoverable has-large-vertical-padding">
                <div className="card-content">
                  <div className="dashboard">
                    <StateIndicator
                      state="is-inactive"
                      classNames={'small has-text-weight-semibold'}
                    >
                      Inactive
                    </StateIndicator>
                    <p className="title is-2 mt-5">Stake with MantraDAO</p>

                    <div className="columns m-0 is-mobile cirus-staking last-element">
                      <div className="column is-narrow">
                        <AmountBox
                          topTxt="APY"
                          middleTxt="~16%"
                          className="is-small"
                        />
                      </div>
                    </div>

                    <button
                      className="button is-primary is-outlined is-large is-rounded is-disabled"
                      disabled
                    >
                      Inactive
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isRedirectingActive ? (
        <RedirectModal
          isActive={isRedirectingActive}
          onClose={() => setIsRedirectingActive(false)}
        />
      ) : null}
    </>
  );
};

StakingView.propTypes = {
  stakedContract: PropTypes.number,
  rewards: PropTypes.number,
  rewardsUSD: PropTypes.number,
  stakedContractUSD: PropTypes.number,
  stakedPoolUSD: PropTypes.number,
  stakedPool: PropTypes.number,
  apy: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    balances: state.balances,
  };
};

export default connect(mapStateToProps, (dispatch) => ({
  dispatch,
  ...bindActionCreators({ setBreadcrumbs }, dispatch),
}))(StakingView);
