import React from 'react';
import './convertMoveFunds.scss';
import { PropTypes } from 'prop-types';

export const ConvertMoveFundsImportant = ({ onsetModalOverview2 }) => {
  return (
    <div>
      <div className="columns column is-centered">
        <p className="is-size-2 transfer title">Important</p>
      </div>
      <div>
        <p className="transfer subtitle">What&apos;s supported</p>
        <div className="columns is-vcentered">
          <div className="column is-narrow">
            <span className="icon is-large">
              <span className="fa-stack fa-lg">
                <i className="fas fa-circle fa-stack-2x green"></i>
                <i className="fas fa-check fa-stack-1x fa-inverse"></i>
              </span>
            </span>
          </div>
          <div className="column">
            <p className="transfer sub">Moving funds to Ethereum</p>
            <p className="transfer content">
              Deposit of funds takes ~ 7-8 minutes{' '}
            </p>
          </div>
        </div>
      </div>
      <hr className="transfer break" />
      <div>
        <p className="transfer subtitle">What&apos;s not supported</p>
        <div className="columns is-vcenetered">
          <div className="column is-narrow">
            <span className="icon is-large">
              <span className="fa-stack fa-lg">
                <i className="fas fa-circle fa-stack-2x red"></i>
                <i className="fas fa-times fa-stack-1x fa-inverse"></i>
              </span>
            </span>
          </div>
          <div className="column">
            <p className="transfer sub">Delegations to validators</p>
            <p className="transfer content"> </p>
          </div>
        </div>
      </div>
      <div className="columns column is-centered">
        <button
          onClick={onsetModalOverview2}
          className="transfer content button is-primary is-rounded mt-6 pt-2 pr-6 pb-2 pl-6"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

ConvertMoveFundsImportant.propTypes = {
  onsetModalOverview2: PropTypes.any,
};
