import React from 'react';
import { PropTypes } from 'prop-types';
import cirusIcon from '../../../../images/cirusToken.png';

export const ConvertMoveFundsOverview = ({ amount, onTransfer }) => {
  const tokenName = 'Matic Token';
  const tokenIcon =
    'https://dynamic-assets.coinbase.com/085ce26e1eba2ccb210ea85df739a0ca2ef782747e47d618c64e92b168b94512df469956de1b667d93b2aa05ce77947e7bf1b4e0c7276371aa88ef9406036166/asset_icons/57f28803aad363f419a950a5f5b99acfd4fba8b683c01b9450baab43c9fa97ea.png';
  return (
    <div>
      <div className="columns column is-centered">
        <p className="is-size-2 transfer title">Transfer Overview</p>
      </div>
      <div className="columns column is-centered">
        <p className="transfer subtitle">
          Deposit process for Cirus consists of a single transaction.
        </p>
      </div>
      <div className="columns column is-centered">
        <p className="is-size-6 transfer content">
          Estimation of total gas required for this transaction
        </p>
      </div>
      <div className="columns is-centered is-vcentered p-2 m-6  has-background-light">
        <div className="column is-narrow">
          <span className="icon is-large">
            <span className="fa-stack fa-lg">
              <i className="fas fa-circle fa-stack-2x green"></i>
              <i className="fas fa-check fa-stack-1x fa-inverse"></i>
            </span>
          </span>
        </div>
        <div className="column">
          <p className=" transfer sub">Complete Deposit</p>
        </div>
        <div className="column is-narrow">
          <img
            alt={`${tokenName} icon`}
            src={cirusIcon}
            width="16"
            // height="20"
          />
        </div>
        <div className="column is-narrow">
          <p className="transfer sub">- {amount}</p>
        </div>
      </div>
      <div className="columns column is-centered">
        <button
          className="transfer content button is-primary is-rounded mt-6 pt-2 pr-6 pb-2 pl-6"
          onClick={onTransfer}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

ConvertMoveFundsOverview.propTypes = {
  amount: PropTypes.any,
  onTransfer: PropTypes.any,
};
