import React, { useState } from 'react';
import { ConvertProgressBar } from './convertProgressBar';
import { PropTypes } from 'prop-types';

export const ConvertMoveFundsProgress = ({ amount, depositTx }) => {
  const [isApproved, setIsApproved] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);

  const tokenName = 'Cirus MATIC';
  const tokenNameShort = 'CIRUS';
  const tokenQuantity = amount;
  const tokenIcon =
    'https://assets.coingecko.com/coins/images/17798/small/8p0Bvi90_400x400.jpg?1629281912';
  return (
    <div>
      <div className="columns column is-centered mb-0">
        <p className="transfer title">Transfer in progress</p>
      </div>
      <hr className="mb-1" />
      <div className="columns is-centered is-vcentered mb-0">
        <div className="column">
          <p className="transfer sub">Transfer Amount</p>
        </div>
        <div className="column is-narrow">
          <img
            alt={`${tokenName} icon`}
            src={tokenIcon}
            width="18"
            height="18"
          />
        </div>
        <div className="column is-narrow has-text-right">
          <p className="transfer sub">{tokenQuantity}</p>
          <p className="transfer sub">{tokenNameShort}</p>
        </div>
      </div>
      <hr className="mt-1" />
      <ConvertProgressBar
        isApproved={isApproved}
        isConfirmed={isConfirmed}
        isCompleted={isCompleted}
      />
      <div className="mt-6">
        {!isCompleted && (
          <div className="columns is-centered">
            <div className="column has-text-centered">
              <p className="transfer subtitle">Transfer en route</p>
              <p className="transfer content">
                Your transfer is en-route. It will take ~ 7-8 minutes for the
                deposit to get completed. On completion, your balance will be
                updated.
              </p>
            </div>
          </div>
        )}
        {isCompleted && (
          <div className="columns is-centered">
            <div className="column has-text-centered">
              <p className="transfer subtitle">
                Transfer completed successfully
              </p>
              <p className="transfer content">
                Your transfer is completed successfully.
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="columns column is-centered">
        <a
          target="_blank"
          href={`https://goerli.etherscan.io/tx/${depositTx}`}
          className="transfer content has-text-centered is-primary mt-6"
          rel="noreferrer"
        >
          View on Etherscan
        </a>
      </div>
    </div>
  );
};

ConvertMoveFundsProgress.propTypes = {
  amount: PropTypes.any,
  depositTx: PropTypes.any,
};
