/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { throttle } from 'lodash';

export const useBreakpoint = () => {
  const [breakPoint, setBreakPoint] = useState(() =>
    getDeviceConfig(window.innerWidth),
  );
  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      console.log(getDeviceConfig(window.innerWidth));
      if (breakPoint !== getDeviceConfig(window.innerWidth)) {
        setBreakPoint(getDeviceConfig(window.innerWidth));
      }
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);
  return breakPoint;
};
const getDeviceConfig = (width) => {
  if (width < 420) {
    return 'xxs';
  } else if (width >= 420 && width < 576) {
    return 'xs';
  } else if (width >= 576 && width < 769) {
    return 'sm';
  } else if (width >= 769 && width < 1024) {
    return 'md';
  } else if (width >= 1024 && width < 1200) {
    return 'lg';
  } else if (width >= 1200 && width < 1400) {
    return 'xl';
  } else {
    return 'xxl';
  }
};
