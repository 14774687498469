import React from 'react';
import CheckSignGreen from '../../../../../components/svg/checkSignGreen';
import PropTypes from 'prop-types';

export const MantraConfirmedUnstake = ({ action, amount, tx }) => {
  return (
    <div className="has-text-centered mb-6 mr-6 ml-6">
      {/* <img src={CheckSign} alt="Check Sign" /> */}
      <CheckSignGreen />
      <p className="title is-2 mt-6 mb-5">
        {action == 'staking' ? 'Staked' : 'Unstaked'}
      </p>
      <p className="has-text-weight-bold">
        <small>Amount staked</small>
      </p>
      <p className="subtitle is-5 has-text-weight-bold">{amount} CIRUS</p>

      <button className="button is-primary-is-outlined is-rounded has-text-primary mt-4">
        {tx ? (
          <a
            style={{ color: 'unset' }}
            rel="noreferrer"
            target="_blank"
            href={
              process.env.REACT_APP_NETWORK_ETHEREUM == 1
                ? `https://etherscan.io/tx/${tx}`
                : `https://goerli.etherscan.io/tx/${tx}`
            }
          >
            TX: {tx.slice(0, 5)}...{tx.slice(-5)}
          </a>
        ) : null}
      </button>
    </div>
  );
};

MantraConfirmedUnstake.propTypes = {
  action: PropTypes.string,
  amount: PropTypes.number,
};
