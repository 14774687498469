import React from 'react';
import { PropTypes } from 'prop-types';

export const DashboardIcon = ({ imageSrc, imageDesc, imageClasses = '' }) => {
  return (
    <div className="columns is-mobile is-vcentered is-centered dash-icon has-background-primary-dull has-background-circle has-border-white box p-0">
      {/* <div className="column is-narrow p-0"> */}
      <img
        className={`column is-narrow icon-size ${imageClasses}`}
        src={imageSrc}
        alt={imageDesc}
      />
      {/* </div> */}
    </div>
  );
};
DashboardIcon.propTypes = {
  imageSrc: PropTypes.string,
  imageDesc: PropTypes.string,
  imageClasses: PropTypes.string,
};
