/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './topNavbar.scss';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import logoImg from '../../images/Cirus.png';
import breadcrumbIcon from '../../images/breadcrumbIcon.svg';
import HelpIcon from '../../images/Help.svg';
import WarningIcon from '../../images/Warning.svg';
import metamaskIcon from '../../images/metamaskIcon.png';
import logoutIcon from '../../images/Logout.svg';
import { injected } from '../../components/wallet/connectors';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import CirusToken from '../../contracts/abis/CirusToken.json';
import StakingContract from '../../contracts/abis/StakingContract.json';
import NewStakingContract from '../../contracts/abis/NewStakingContract.json';
import StakingPoolContract from '../../contracts/abis/StakingPool.json';

import {
  setBalanceTotal,
  setBalanceReward,
  setBalanceLiquidEthereum,
  setBalanceLiquidPolygon,
  setBalanceLiquid,
  setBalanceTotalStaked,
  setBalanceStakedPool,
  setBalanceStakedContract,
  setBalanceStakedContractUSD,
  setBalanceStakedPoolUSD,
  setBalanceTotalUSD,
  setBalanceApy,
  setBalanceRewardUSD,
  setBalanceLiquidUSD,
  setBalanceLiquidUSDEthereum,
  setBalanceLiquidUSDPolygon,
  setBalanceTotalStakedUSD,
  setStakingContractTotalBalance,
  setStakingContractTotalBalanceUSD,
} from './../../store/actions/balancesActions';
import { initialUSDValue } from './../../store/initialState/currency';
import { fetchCirusValueUSD } from './../../store/actions/currencyConvertActions';
import NavbarTopTable from '../../components/tables/navbarTopTable';

const config = require('../../config');

const TopNavbar = (props) => {
  const { isPrimary, isSticky, breadcrumbs, currencyConversion } = props;
  const { active, account, library, activate, chainId } = useWeb3React();
  const [wrongNetwork, setWrongNetwork] = useState(false);


  const connectWallet = async () => {
    try {
      await activate(injected);
      if (chainId) getBalances();
    } catch (err) {
      console.log(err);
    }
  };
 
  const switchNetwork = async () => {
    library.provider.request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          chainId: `0x${process.env.REACT_APP_NETWORK_ETHEREUM}`,
        },
      ],
    });
  };

  const getContract = async (instance, signer, address) => {
    const abi = instance.abi;
    const contract = new ethers.Contract(address, abi, signer);
    return { contract, address };
  };
  const getBalances = async () => {
    // eslint-disable-next-line eqeqeq
    if (
      active &&
      (process.env.REACT_APP_NETWORK_ETHEREUM == chainId ||
        process.env.REACT_APP_NETWORK_POLYGON == chainId)
    ) {
      const ethereumCirusContractData = await getContract(
        CirusToken,
        chainId == process.env.REACT_APP_NETWORK_ETHEREUM
          ? library.getSigner()
          : new ethers.VoidSigner(
              account,
              new ethers.providers.InfuraProvider(
                parseInt(process.env.REACT_APP_NETWORK_ETHEREUM),
                config.infuraId,
              ),
            ),
        process.env.REACT_APP_CIRUS_TOKEN_ETHEREUM,
      );

      const polygonCirusContractData = await getContract(
        CirusToken,
        chainId == process.env.REACT_APP_NETWORK_POLYGON
          ? library.getSigner()
          : new ethers.VoidSigner(
              account,
              new ethers.providers.InfuraProvider(
                parseInt(process.env.REACT_APP_NETWORK_POLYGON),
                config.infuraId,
              ),
            ),
        process.env.REACT_APP_CIRUS_TOKEN_POLYGON,
      );

      let cirusStakingContractStakedBalance,
        cirusStakingRewardsBalance,
        cirusStakingPoolStakedBalance;

      let ethereumCirusTokenLiquidBalance = (
        await ethereumCirusContractData.contract.functions.balanceOf(account)
      )[0];
      ethereumCirusTokenLiquidBalance = parseFloat(
        ethers.utils.formatEther(ethereumCirusTokenLiquidBalance.toString()),
      ).toFixed(2);

      let polygonCirusTokenLiquidBalance = (
        await polygonCirusContractData.contract.functions.balanceOf(account)
      )[0];
      polygonCirusTokenLiquidBalance = parseFloat(
        ethers.utils.formatEther(polygonCirusTokenLiquidBalance.toString()),
      ).toFixed(2);

      //metamask on Ethereum
      //staked balances and rewards here
      if (chainId == process.env.REACT_APP_NETWORK_ETHEREUM) {
        const stakingContractData = await getContract(
          NewStakingContract,
          library.getSigner(),
          process.env.REACT_APP_STAKING_CONTRACT_ETHEREUM,
        );

        const stakingPoolContractData = await getContract(
          StakingPoolContract,
          library.getSigner(),
          process.env.REACT_APP_MANTRADAO,
        );

        cirusStakingContractStakedBalance = (
          await stakingContractData.contract.functions.stakeHistory(account)
        )[0];
        cirusStakingContractStakedBalance = parseFloat(
          cirusStakingContractStakedBalance / 10 ** 18,
        ).toFixed(2);

        let apy = (
          await stakingContractData.contract.functions.estimatedAPY()
        )[0];
        apy = parseFloat(apy / 100).toFixed(2);
        props.setBalanceApy(apy);

        cirusStakingPoolStakedBalance =
          (
            await stakingPoolContractData.contract.functions.balanceOf(account)
          )[0] /
          10 ** 18;

        cirusStakingRewardsBalance =
          (
            await stakingContractData.contract.functions.pendingReward(account)
          )[0] /
          10 ** 18;

        cirusStakingRewardsBalance = parseFloat(
          cirusStakingRewardsBalance,
        ).toFixed(2);

        let stakingContractTotalBalance =
          (
            await stakingContractData.contract.functions.stakeHistory(account)
          )[0] /
          10 ** 18;

        props.setStakingContractTotalBalance(stakingContractTotalBalance);
        props.setStakingContractTotalBalanceUSD(
          parseFloat(stakingContractTotalBalance) *
            currencyConversion.USDToCirus,
        );

        props.setBalanceStakedPool(cirusStakingPoolStakedBalance);
        props.setBalanceStakedPoolUSD(
          (
            cirusStakingPoolStakedBalance * currencyConversion.USDToCirus
          ).toFixed(2),
        );
        props.setBalanceStakedContract(cirusStakingContractStakedBalance);
        props.setBalanceStakedContractUSD(
          (
            cirusStakingContractStakedBalance * currencyConversion.USDToCirus
          ).toFixed(2),
        );

        {
          /*let cirusStakingPoolRewardsBalanceHex = (
          await stakingPoolContractData.contract.functions.calculateUnstake(
            account,
            ethers.utils.formatEther(cirusStakingPoolStakedBalance.toString()),
          )
        )[0];

        let cirusStakingPoolRewardsBalance =
          cirusStakingPoolRewardsBalanceHex.toString();
        cirusStakingPoolRewardsBalance = parseFloat(
          cirusStakingPoolRewardsBalance,
        ).toFixed(2);*/
        }
        //end staking pool
        props.setBalanceTotalStaked(
          parseFloat(cirusStakingContractStakedBalance) +
            parseFloat(cirusStakingPoolStakedBalance),
        );

        props.setBalanceReward(cirusStakingRewardsBalance);

        props.setBalanceTotalStakedUSD(
          (
            (parseFloat(cirusStakingContractStakedBalance) +
              parseFloat(cirusStakingPoolStakedBalance)) *
            currencyConversion.USDToCirus
          ).toFixed(2),
        );

        props.setBalanceRewardUSD(
          (cirusStakingRewardsBalance * currencyConversion.USDToCirus).toFixed(
            2,
          ),
        );
      }

      let totalBalance = (
        parseFloat(
          chainId == process.env.REACT_APP_NETWORK_ETHEREUM
            ? ethereumCirusTokenLiquidBalance
            : polygonCirusTokenLiquidBalance,
        ) +
        parseFloat(
          cirusStakingContractStakedBalance
            ? cirusStakingContractStakedBalance
            : 0,
        ) +
        parseFloat(
          cirusStakingPoolStakedBalance ? cirusStakingPoolStakedBalance : 0,
        ) +
        parseFloat(cirusStakingRewardsBalance ? cirusStakingRewardsBalance : 0)
      ).toString();

      props.setBalanceLiquidEthereum(ethereumCirusTokenLiquidBalance);
      props.setBalanceLiquidPolygon(polygonCirusTokenLiquidBalance);
      props.setBalanceLiquid(
        chainId == process.env.REACT_APP_NETWORK_ETHEREUM
          ? ethereumCirusTokenLiquidBalance
          : polygonCirusTokenLiquidBalance,
      );

      props.setBalanceTotal(totalBalance);

      // convert cirus to USD and set
      props.setBalanceLiquidUSD(
        chainId == process.env.REACT_APP_NETWORK_ETHEREUM
          ? ethereumCirusTokenLiquidBalance * currencyConversion.USDToCirus
          : polygonCirusTokenLiquidBalance * currencyConversion.USDToCirus,
      );

      props.setBalanceLiquidUSDEthereum(
        ethereumCirusTokenLiquidBalance * currencyConversion.USDToCirus,
      );
      props.setBalanceLiquidUSDPolygon(
        polygonCirusTokenLiquidBalance * currencyConversion.USDToCirus,
      );

      props.setBalanceTotalUSD(
        (totalBalance * currencyConversion.USDToCirus).toFixed(2),
      );
    } else {
      setWrongNetwork(true);
    }
  };

  const getBreadCrumbs = (breadcrumbs) => {
    const landingPageCrumb = (
      <li key="dashboard">
        <a
          href="/"
          className="is-flex is-justify-content-center is-align-content-center"
        >
          <img className="mr-2" src={breadcrumbIcon} alt="Dashboard" />
        </a>
        <a href="/">
          <small className="nav">Dashboard</small>
        </a>
      </li>
    );
    const remainingPageCrumbs = breadcrumbs.map((crumb, index) => (
      <li
        className={`${index === breadcrumbs.length - 1 ? 'is-active' : ''}`}
        key={crumb.name}
      >
        <a href={crumb.link}>
          <small className="nav">{crumb.name}</small>
        </a>
      </li>
    ));

    return [landingPageCrumb].concat(remainingPageCrumbs);
  };

  useEffect(() => {
    if (currencyConversion.USDToCirus === initialUSDValue().USDToCirus) {
      props.dispatch(fetchCirusValueUSD());
    }
  }, []);

  React.useEffect(() => {
    connectWallet();

    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      });
      
      window.ethereum.on('accountsChanged', () => {
        window.location.reload();
      });
    }

  },[account, chainId]);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    connectWallet();
  }, [account, chainId]);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    const interval = setInterval(() => {
      if (chainId) getBalances();
    }, 3500);
    return () => clearInterval(interval);
  }, [account, chainId]);



  return (
    <div className={`topNavbar ${isSticky ? 'sticky-navbar' : ''}`}>
      <nav
        className={`navbar is-transparent ${isPrimary ? 'is-primary' : ''}`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img src={logoImg} alt="Cirus Logo" />
            </a>
          </div>
            <div className="navbar-top-table">
              <NavbarTopTable />
            </div>
          <div className="connect-wallet is-pulled-right">
            {wrongNetwork === true ? (
              <WrongNetwork switchNetwork = {switchNetwork} />
            ) : (
              <>
                <button
                  className="wallet button is-medium is-rounded has-text-primary"
                  onClick={connectWallet}
                >
                  <img
                    src={metamaskIcon}
                    className="mr-3"
                    alt="Metamask Icon"
                    width="32"
                  />
                  {active
                    ? account.substr(0, 4) + '...' + account.substr(-4)
                    : 'Connect Wallet'}
                </button>
              </>
            )}
          </div>
        </div>
      </nav>

      <div
        className={`breadcrumb-section 
        ${breadcrumbs.length !== 0 ? '' : 'is-hidden'}
        `}
      >
        <div className="container">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>{breadcrumbs.length !== 0 && getBreadCrumbs(breadcrumbs)}</ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

const WrongNetwork = ( {switchNetwork} ) => {
  return (<span className="wrong-network is-flex is-justify-content-center is-align-items-center">
    <button className="helper-icon" >
      <img src={HelpIcon} alt="Help Icon" />
      <div className="uptick"></div>
      <div className="info">
        <p>
        You may be on the wrong network. Please switch to the correct network to continue.
        </p>
      </div>
    </button>
    <span>
      <button onClick={switchNetwork} className="button is-danger is-medium is-rounded has-text-primary danger">
        <img
          src={WarningIcon}
          alt="Warning Icon"
          className="mr-4"
        />
        Switch to ETH
      </button>
    </span>
  </span>);
};

TopNavbar.propTypes = {
  isPrimary: PropTypes.bool,
  isSticky: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  currencyConversion: PropTypes.object,
  dispatch: PropTypes.func,
  setBalanceTotal: PropTypes.func,
  setBalanceReward: PropTypes.func,
  setBalanceTotalStaked: PropTypes.func,
  setBalanceStakedContract: PropTypes.func,
  setBalanceStakedPool: PropTypes.func,
  setBalanceLiquidEthereum: PropTypes.func,
  setBalanceLiquidPolygon: PropTypes.func,
  setBalanceLiquid: PropTypes.func,
  setBalanceStakedPoolUSD: PropTypes.func,
  setBalanceStakedContractUSD: PropTypes.func,
  setBalanceTotalUSD: PropTypes.func,
  setBalanceRewardUSD: PropTypes.func,
  setBalanceTotalStakedUSD: PropTypes.func,
  setBalanceLiquidUSD: PropTypes.func,
  setBalanceLiquidUSDEthereum: PropTypes.func,
  setBalanceLiquidUSDPolygon: PropTypes.func,
  setBalanceApy: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    currencyConversion: state.currencyConversion,
  };
};
export default connect(mapStateToProps, (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      setBalanceLiquid,
      setBalanceLiquidEthereum,
      setBalanceLiquidPolygon,
      setBalanceStakedPool,
      setBalanceStakedContract,
      setBalanceTotalStaked,
      setBalanceReward,
      setBalanceStakedPoolUSD,
      setBalanceStakedContractUSD,
      setBalanceTotalUSD,
      setBalanceRewardUSD,
      setBalanceTotalStakedUSD,
      setBalanceLiquidUSD,
      setBalanceLiquidUSDEthereum,
      setBalanceLiquidUSDPolygon,
      setBalanceTotal,
      setBalanceApy,
      setStakingContractTotalBalance,
      setStakingContractTotalBalanceUSD,
    },
    dispatch,
  ),
}))(TopNavbar);
