import React from 'react';
import { PropTypes } from 'prop-types';

export const Modal = ({ isActive, onClose, children, classes = '' }) => {
  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <div className={`box ${classes}`}>{children}</div>
        <button
          className="modal-close-button"
          aria-label="close"
          title="Close"
          onClick={onClose}
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.element,
  classes: PropTypes.string,
};
