import React from 'react';
import { connect } from 'react-redux';
import AmountBox from '../amountBox';
import totalIcon from '../../images/Cirus Dash Icons/1TotalBalance@4x.png';
import liquidIcon from '../../images/Cirus Dash Icons/2LiquidBalance@4x.png';
import stakedIcon from '../../images/Cirus Dash Icons/3StakedBalance@4x.png';
import rewardIcon from '../../images/Cirus Dash Icons/4RewardBalance@4x.png';
import multiplierIcon from '../../images/Cirus Dash Icons/5MultiplierAmount@4x.png';
import engagementIcon from '../../images/Cirus Dash Icons/6EngagementIndex@4x.png';
import dataRevenueIcon from '../../images/Cirus Dash Icons/7DataRevenue@4x.png';
import currentTierIcon from '../../images/Cirus Dash Icons/8CurrentTier@4x.png';
import './desktopTopTable.scss';

// eslint-disable-next-line react/prop-types
const DesktopTopTable = ({ balances }) => {
  // eslint-disable-next-line react/prop-types
  const { total, liquid, rewards, staked } = balances;
  return (
    <div className="desktop-top-table">
      <table className="table is-bordered is-fullwidth">
        <tbody>
          <tr>
            <td>
              <AmountBox
                topTxt="Total Balance"
                middleTxt={`${total} CIRUS`}
                bottomTxt="$28,392.39 USD"
                avatar={totalIcon}
              />
            </td>
            <td>
              <AmountBox
                topTxt="Liquid Balance"
                middleTxt={`${liquid} CIRUS`}
                bottomTxt="$8,394.39 USD"
                avatar={liquidIcon}
              />
            </td>
            <td>
              <AmountBox
                topTxt="Staked Balance"
                middleTxt={`${staked} CIRUS`}
                bottomTxt="$18,000.00 USD"
                avatar={stakedIcon}
              />
            </td>
            <td>
              <AmountBox
                topTxt="Reward Balance"
                middleTxt={`${rewards} CIRUS`}
                bottomTxt="$2,000.00 USD"
                avatar={rewardIcon}
              />
            </td>
          </tr>
          {/* <tr>
            <td>
              <AmountBox
                topTxt="Multiplier Amount"
                middleTxt="0.5x"
                avatar={multiplierIcon}
              />
            </td>
            <td>
              <AmountBox
                topTxt="Engagement Index"
                middleTxt="500"
                avatar={engagementIcon}
              />
            </td>
            <td>
              <AmountBox
                topTxt="Total Data Revenue"
                middleTxt="Coming soon"
                avatar={dataRevenueIcon}
              />
            </td>
            <td>
              <AmountBox
                topTxt="Current Tier"
                middleTxt="Tier 3"
                avatar={currentTierIcon}
              />
            </td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    balances: state.balances,
  };
};
export default connect(mapStateToProps)(DesktopTopTable);
