import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const ConditionalRoute = ({ path, exact, redirectTo, children }) => {
  const location = useLocation();
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        redirectTo ? (
          <Redirect
            {...props}
            to={{
              pathname: redirectTo,
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};

ConditionalRoute.propTypes = {
  path: PropTypes.string,
  exact: PropTypes.bool,
  redirectTo: PropTypes.string,
  children: PropTypes.object,
};

export { ConditionalRoute };
