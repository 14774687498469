import React from 'react';
import { PropTypes } from 'prop-types';
import './convertTokenTile.scss';

export const TransactionTile = ({
  tokenName,
  tokenNameShort,
  tokenQuantity,
  tokenIcon,
  dollarValue,
  action,
  isCheckpointed,
  isDeposited,
  date,
  withdraw,
  hash,
}) => {
  return (
    <tr>
      <th />
      <th className="convert-table-icon vcenter is-narrow">
        <div className="token-background">
          <img
            alt={`${tokenName} icon`}
            src={tokenIcon}
            width="18"
            height="18"
          />
        </div>
      </th>
      <td className="vcenter">
        <p>{tokenName}</p>
      </td>
      <td />
      <td className="vcenter action">
        <p>{`${action}`}</p>
      </td>
      <td className="vcenter">
        <p>
          {`${new Intl.NumberFormat().format(
            parseFloat(tokenQuantity),
          )} ${tokenNameShort}`}
        </p>
      </td>
      <td className="vcenter">
        {`$${new Intl.NumberFormat().format(
          parseFloat(dollarValue).toFixed(2),
        )}`}
      </td>
      <td className="vcenter">
        <p>{date}</p>
      </td>
      <td className="vcenter">
        {isCheckpointed === true && isDeposited === false ? (
          <p
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={(e) => withdraw(hash)}
          >
            Withdraw
          </p>
        ) : isCheckpointed === false && isDeposited === false ? (
          <p>Pending</p>
        ) : (
          <p>Completed</p>
        )}
      </td>
      <td />
    </tr>
  );
};

TransactionTile.propTypes = {
  tokenName: PropTypes.string,
  tokenNameShort: PropTypes.string,
  tokenQuantity: PropTypes.string,
  tokenIcon: PropTypes.string,
  dollarValue: PropTypes.string,
  action: PropTypes.string,
  isCheckpointed: PropTypes.bool,
  isDeposited: PropTypes.bool,
  date: PropTypes.string,
  withdraw: PropTypes.func,
  hash: PropTypes.string,
};
