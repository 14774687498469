import React from 'react';
import PropTypes from 'prop-types';
import './transactionSuccessModal.scss';
import successCheck from '../../images/g_checkmark.png';

const TransactionSuccessModal = (props) => {
  const { isActive, onClose, wallet, tx } = props;

  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <button
          className="modal-close-button"
          aria-label="close"
          title="Close"
          onClick={onClose}
        >
          <i className="fa fa-times"></i>
        </button>
        <div className="box transactionSuccessBox">
          <div className="columns">
            <div className="column mt-5">
              <div className="success-img">
                <img alt="Icon: Success Check" src={successCheck} width="72" />
              </div>
              <p className="title is-4">Transaction Successful</p>
              <div className="pl-6 pr-6 mb-6 has-text-centered">
                <button className="button is-primary-is-outlined is-rounded has-text-primary mt-4">
                  {tx ? (
                    <a
                      style={{ color: 'unset' }}
                      rel="noreferrer"
                      target="_blank"
                      href={
                        process.env.REACT_APP_NETWORK_ETHEREUM == 1
                          ? `https://etherscan.io/tx/${tx}`
                          : `https://goerli.etherscan.io/tx/${tx}`
                      }
                    >
                      TX: {tx.slice(0, 5)}...{tx.slice(-5)}
                    </a>
                  ) : null}
                </button>
              </div>
              <div className="success-img">
                <button
                  className="button is-primary is-rounded"
                  onClick={onClose}
                >
                  Back to Staking
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TransactionSuccessModal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  wallet: PropTypes.string,
};

export default TransactionSuccessModal;
