import React from 'react';
import './loadingModal.scss';

const LoadingModal = (props) => {
  return (
    <div className={'modal is-active'}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="card">
          <div className="card-content has-text-centered">
            <p className="title is-4">Loading...</p>
            <i className="fas fa-circle-notch fa-5x fa-spin has-text-primary" />
          </div>
          <center className="p-2 scale-90 text-xs pb-4"><strong>Do not refresh or leave this page</strong></center>
        </div>
      </div>
    </div>
  );
};

export default LoadingModal;
