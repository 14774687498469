import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InlineNotification from '../../components/inlineNotification/inlineNotification';
import FAQImage from '../../images/cirusResourceFAQ.png';
import convertTokensImage from '../../images/cirusConvertToken.png';
import stakeImage from '../../images/cirusToken.png';
import ExtensionIcon from '../../images/Cirus-Extension-Icon@2x.png';
import mainPageTopNavBackground from '../../images/mainPageTopNavBackground.png';
import { DashboardIcon } from './../../components/images/icons/dashboardIcons';
import { Modal } from '../../components/modals/modal';
import NotificationsModal from './components/notificationsModal';
import { TopTable } from '../../components/tables/topTable';
import ConvertModal from '../../components/convertModal/convertModal' 
import { fetchCirusValueUSD } from '../../store/requests/currency';
import { setBreadcrumbs } from '../../store/actions/mobileTableActions';
import './homeView.scss';

// eslint-disable-next-line react/prop-types
const HomeView = (props) => {
  const [isNotificationModalActive, setNotificationModalActive] =
    useState(false);
  const [redirectinActive, setIsRedirectingActive] = useState(false)
  const toggleNotificationsModal = () => {
    setNotificationModalActive(!isNotificationModalActive);
  };

  useEffect(() => {
    props.setBreadcrumbs([]);
  }, []);

  return (
    <>
      <div className="landing-page-header">
        <img
          src={mainPageTopNavBackground}
          style={{ right: '0px', position: 'absolute' }}
        ></img>
        <section className="section">
          <div className="container main-container">
            <p className="title welcome-msg is-1 has-text-white whitespace-nowrap">
              CIRUS TOKEN HUB
            </p>
            <div className="columns inline-notification-container">
              <button
                onClick={toggleNotificationsModal}
                className="column p-0 styless"
              >
                {/*<InlineNotification>
                  <p className="text-overflow-ellipsis has-text-left">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod
                  </p>
                </InlineNotification>*/}
              </button>
            </div>
            <TopTable />
            <div className="columns card-container">
              <div className="column is-4">
                <div className="dashboard card is-hoverable">
                  <div className="card-content">
                    <div className="dashboard">
                      <DashboardIcon
                        imageSrc={stakeImage}
                        imageDesc="Stake"
                        imageClasses="p-2"
                      />
                      <p className="title card-title is-2">Stake</p>
                      <p className="card-text last-element">
                        Stake your Cirus Tokens to earn more while strengthening
                        the entire Cirus Ecosystem!
                      </p>
                      <a
                        className="button is-primary is-outlined is-rounded is-large"
                        href="/staking"
                      >
                        Staking Pools
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="dashboard card is-hoverable">
                  <div className="card-content">
                    <div className="dashboard">
                      <DashboardIcon
                        imageSrc={convertTokensImage}
                        imageDesc="Convert Tokens Icon"
                        imageClasses="p-1"
                      />
                      <p className="title card-title is-2">Convert Tokens</p>
                      <p className="card-text last-element">
                        Move your Cirus Tokens between the Ethereum and Polygon
                        Networks
                      </p>
                      <a
                        className="button is-primary is-large is-outlined is-rounded"
                        onClick={() => setIsRedirectingActive(true)}
                      >
                        Convert
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="dashboard card is-hoverable">
                  <div className="card-content">
                    <div className="dashboard">
                      <DashboardIcon imageSrc={FAQImage} imageDesc="FAQ Icon" />
                      <p className="title card-title is-2">Resources & FAQ</p>
                      <p className="card-text last-element">
                        Find How-To&apos;s, and more information about the Cirus
                        Token Ecosystem
                      </p>
                      <button
                        className="button is-primary is-large is-outlined is-rounded"
                        onClick={() => {
                          window.open(
                            'https://support.cirusfoundation.com/en/knowledge',
                          );
                        }}
                      >
                        View Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns card-container">
              <div className="column is-4">
                <div className="dashboard card is-hoverable">
                  <span className="status">Coming soon</span>
                  <div className="card-content">
                    <div className="dashboard">
                      <DashboardIcon
                        imageSrc={ExtensionIcon}
                        imageDesc="FAQ Icon"
                      />
                      <p className="title card-title is-2">Cirus Wallet</p>
                      <p className="card-text last-element">
                        Connect your Cirus wallet - Coming Soon
                      </p>
                      {/* <button className="button is-primary is-large is-outlined is-rounded">
                      Cirus Wallet
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {redirectinActive ? (
        <ConvertModal
          isActive={redirectinActive}
          onClose={() => setIsRedirectingActive(false)}
        />
      ) : null}
      {/* <Modal
        isActive={isNotificationModalActive}
        onClose={toggleNotificationsModal}
        classes={'p-0'}
      >
        <NotificationsModal />
      </Modal> */}
    </>
  );
};

HomeView.propTypes = {};
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, (dispatch) => ({
  ...bindActionCreators({ setBreadcrumbs }, dispatch),
}))(HomeView);
