import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './inlineNotification.scss';
import notificationIcon from '../../images/inlineNotificationIcon.svg';

const InlineNotification = (props) => {
  const { children } = props;
  const [isClosed, setIsClosed] = useState(false);

  return (
    <div
      className={`level columns is-gapless inline-notification is-mobile ${
        isClosed ? 'is-hidden' : ''
      }`}
    >
      <div className="column is-narrow">
        <div className="notification-icon-wrapper">
          <img
            className="notification-icon"
            src={notificationIcon}
            alt="Notification Icon"
          />
          <div className="overlayed-notification-hint is-hidden-touch" />
        </div>
      </div>
      <div className="text-overflow-ellipsis column pr-10">{children}</div>
      <button
        className="inline-notification-close"
        title="Close"
        onClick={() => setIsClosed(true)}
      >
        <i className="fa fa-times"></i>
      </button>
    </div>
  );
};

InlineNotification.propTypes = {
  children: PropTypes.any,
};

export default InlineNotification;
