import React from 'react';
import PropTypes from 'prop-types';
import './stateIndicator.scss';

const StateIndicator = (props) => {
  const { children, state, classNames } = props;

  return (
    <div className={`state-indicator ${state}`}>
      <span className="state-visual-indicator"></span>
      <span className="state-text pb-2">
        <p className={`is-inline-block ${classNames}`}>{children}</p>
      </span>
    </div>
  );
};

StateIndicator.propTypes = {
  children: PropTypes.any,
  state: PropTypes.string,
  classNames: PropTypes.string,
};

export default StateIndicator;
