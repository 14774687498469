import React from 'react';
import AmountBox from '../../../../components/amountBox';
import ethIcon from '../../../../images/ethicon.png';
import { PropTypes } from 'prop-types';

export const MantraUnstakeModal = ({ amount, setStep, setAmountToUnstake }) => {
  return (
    <>
      <div className="columns is-centered mt-6 mb-6">
        <div className="column is-4 ml-6">
          <AmountBox middleTxt="Cirus / ETH" avatar={ethIcon} />
        </div>
      </div>
      <div className="has-text-centered mb-6 mr-6 ml-6">
        <div className="notification pt-5">
          <p className="title is-2 mb-5">You are unstaking</p>
          <p
            className="subtitle is-2"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <input
              type="text"
              value={amount}
              className="transfer-input input is-rounded is-large"
              style={{ width: 'auto' }}
              onChange={(e) => setAmountToUnstake(e.target.value)}
            />
            Cirus
          </p>
        </div>
        <p className="mt-6 has-text-left">
          <span className="has-text-primary">*</span> There is an 8 days
          Unstaking period before you can Withdraw your Staked tokens. Following
          that 8 day Unstaking period you will be able to Withdraw .
        </p>
        <p className="mt-3 has-text-left">
          <span className="has-text-primary">*</span> Staking Rewards will stop
          being earned as soon as you click “Unstake” and initiate the Unstaking
          process.
        </p>

        <p className="mb-5 mt-6">
          <button
            className="button is-primary is-rounded is-large"
            onClick={(e) => setStep('unstaking')}
          >
            Unstake
          </button>
        </p>
      </div>
    </>
  );
};
MantraUnstakeModal.propTypes = {
  amount: PropTypes.number,
  setAmountToUnstake: PropTypes.func,
  setStep: PropTypes.func,
};
