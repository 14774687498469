export const getEthereumTransactions = async (account) => {
  const data = await fetch(
    `${process.env.REACT_APP_ETHERSCAN_ENDPOINT}?module=account&action=txlist&address=${account}&startblock=6000000&page=1&sort=desc&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`,
  );
  const transactions = await data.json();

  return {
    transactions,
  };
};

export const getPolygonTransactions = async (account) => {
  const data = await fetch(
    `${process.env.REACT_APP_POLYGONSCAN_ENDPOINT}?module=account&action=txlist&address=${account}&startblock=20000000&page=1&sort=desc&apikey=${process.env.REACT_APP_POLYGONSCAN_API_KEY}`,
  );
  const transactions = await data.json();
  return {
    transactions,
  };
};
