import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useBreakpoint } from '../hooks/useBreakpoint';
import TopNavbar from './_components/topNavbar';
import ScrolledMobileTable from '../components/tables/scrolledMobileTable';
import './layout.scss';

const Layout = (props) => {
  const { children, breadcrumbs } = props;

  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const screenSize = useBreakpoint();

  useEffect(() => {
    if (screenSize === 'sm' || screenSize === 'xs' || screenSize === 'xxs') {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenSize]);

  return (
    <>
      {isMobile ? <ScrolledMobileTable /> : null}
      <TopNavbar
        isPrimary={location.pathname !== '/'}
        isSticky={!isMobile && location.pathname !== '/'}
        breadcrumbs={breadcrumbs}
      />
      {children}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.array,
  breadcrumbs: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    breadcrumbs: state.mobileTable.breadcrumbs,
  };
};
export default connect(mapStateToProps, (dispatch) => ({
  ...bindActionCreators({}, dispatch),
}))(Layout);
