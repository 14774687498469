import { ACTION_TYPES } from '../actionTypes';
import { combineReducers } from 'redux';
import { initialUSDValue } from './../initialState/currency.js';

const USDToCirus = (state = initialUSDValue().USDToCirus, action) => {
  if (action.type === ACTION_TYPES.CURRENCY_SET_CIRUS_USD) {
    return action.payload;
  }
  return state;
};

export default combineReducers({
  USDToCirus,
});
