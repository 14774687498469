import React from 'react';
import PropTypes from 'prop-types';
import './convertModal.scss';
import ExternalLink from '../svg/externalLink';

const SignTransactionModal = (props) => {
  const {
    isActive,
    onClose
  } = props;


  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <button
          className="modal-close-button"
          aria-label="close"
          title="Close"
          onClick={onClose}
        >
          <i className="fa fa-times"></i>
        </button>
        <div className="box signTransactionBox">
          <div className="mt-5 mb-2">
            <p className="title is-4">Important</p>
            <p className="textCenter">
            You are now leaving the Cirus Ecosystem to access the Polygon Bridge
            </p>
          </div>
          {
          // action !== 'Unstake' &&
          // action !== 'Full Unstake' &&
          // action !== 'Claim Reward' ? 
          // : null
          }
          <div className="buttons">
            <button
              className="button is-primary is-medium is-rounded"
              onClick={() =>{window.open('https://wallet.polygon.technology/login/')}}
            >
              Go to Polygon &nbsp; <ExternalLink color="#fff"/>
              
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

SignTransactionModal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  amountToStake: PropTypes.string,
  stake: PropTypes.func,
  unstake: PropTypes.func,
  action: PropTypes.string,
  withdraw: PropTypes.func,
  preApprove: PropTypes.func,
  contractApproved: PropTypes.bool,
  isApproving: PropTypes.bool,
};

export default SignTransactionModal;
