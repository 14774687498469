import DesignElementsView from './views/designElements/designElementsView';
import HomeView from './views/home/homeView';
import MantraDaoStakingView from './views/staking/mantraDaoStaking/mantraDaoStakingView';
import StakingView from './views/staking/stakingView';
import CirusStakingView from './views/staking/cirusStaking/cirusStakingView';
import ConvertTokensView from './views/convertTokens/convertTokensView';
import Layout from './layout/layout';

export const AppRoutesConfig = {
  routes: [
    {
      path: '/',
      component: HomeView,
    },
    {
      path: '/staking',
      component: StakingView,
    },
    {
      path: '/staking/cirus-staking',
      component: CirusStakingView,
    },
    {
      path: '/staking/mantra-dao-staking',
      component: MantraDaoStakingView,
    },
    {
      path: '/convert-tokens',
      component: ConvertTokensView,
    },
    {
      path: '/design-elements',
      component: DesignElementsView,
    },
  ],
  layoutComponent: Layout,
};
