import { ACTION_TYPES } from '../actionTypes';

export const setMobileTableExpanded = (isExpanded) => ({
  type: ACTION_TYPES.MOBILE_TABLE_EXPAND_SET,
  payload: isExpanded,
});
export const setBreadcrumbs = (breadcrumbs) => ({
  type: ACTION_TYPES.SET_BREADCRUMBS,
  payload: breadcrumbs,
});
